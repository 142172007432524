import React, {useState, useEffect} from "react";

import {useForm} from "react-hook-form";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid} from "@mui/material";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import i18next from 'i18next';

import {CustomAxios} from "../../../../custom/axiosCustom";
import {Config} from "../../../../utils/configHeader";
import {ErrorAlert, InfoAlert, SuccessAlert} from "../../../../custom/SwalCustom";
import {ChargedManualComponent} from "./chargedManualComponent";

const schema = yup.object().shape({
    ccEmail: yup.string().email("Debe ser un correo válido"),
}).required();


export const DialogChargedInvoiceAutomaticComponent = ({handleClose, open, notLoading, loading, userData, invoiceId, invoices, handleInvoices}) => {

    const {control, handleSubmit, reset, watch, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const onClose = () => {
        handleClose()
        reset()
    }

    const onRegister = (value) => {
        loading()
        onClose()
        value["invoiceId"] = invoiceId
        CustomAxios.post('invoices/send', value, Config()).then(() => {
            const newInvoices = invoices.map(item => item.invoice !== undefined && item.invoice !== null && item.invoice.id === invoiceId 
                    ? { ...item, invoice: { ...item.invoice, status: 'SENT' } }
                    : item
            );
            handleInvoices(newInvoices)
            SuccessAlert(i18next.t("uploadInvoice"), i18next.t("doneSuccessfully", {a: "a"})).then()
        }).catch((err) => {
            console.error(err)
            // {response: {data}}
            // ErrorAlert("Error al Cargar Factura", data.message).then()
        }).finally(notLoading)
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <form onSubmit={handleSubmit(onRegister)}>
                    <DialogTitle>{i18next.t("automaticInvoiceUpload")}</DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <Grid container spacing={3}>
                                <ChargedManualComponent errors={errors} control={control} defaultValue={userData}/>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>{i18next.t("cancel")}</Button>
                        <Button type="submit">{i18next.t("load")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
