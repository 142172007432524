import MDBox from "../../../components/MDBox";
import { Grid } from "@mui/material";
import {
  AccessTimeFilled,
  InsertDriveFile,
  PersonAdd,
  Receipt,
  Topic,
  Phone,
  House,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

import MenuCard from "../../../examples/Cards/MenuCards";
import { userContainPermission } from "../../../utils/permission";
import { usePermission } from "../../../hooks/usePermission";
import { useTranslation } from "react-i18next";


export const ItemsComponent = () => {
  const { t } = useTranslation();
  const { permissionList } = usePermission("Modulos Permitidos PC");
  const existPermission = (permission) => {
    return userContainPermission(permission, permissionList);
    // return true; // *** Ocupar esto para visualizar los modulos sin permisos ***
  };

  return (
    <>
      <MDBox py={3}>
        <Grid container spacing={3}>
          {existPermission("Vacantes") && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <MDBox mb={1.5}>
                <Link to="/gestion-de-vacantes">
                  <MenuCard
                    icon={InsertDriveFile}
                    title={t('managementVacancy')}
                  />
                </Link>
              </MDBox>
            </Grid>
          )}
          {existPermission("Solicitudes") && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <MDBox mb={1.5}>
                <Link to="/solicitudes">
                  <MenuCard icon={Topic} title={t('managementApplication')} />
                </Link>
              </MDBox>
            </Grid>
          )}
          {existPermission("Carga de horas") && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <MDBox mb={1.5}>
                <Link to="/gestion-de-horas">
                  <MenuCard icon={AccessTimeFilled} title={t('managementHour')} />
                </Link>
              </MDBox>
            </Grid>
          )}
          {existPermission("Factura") && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <MDBox mb={1.5}>
                <Link to="/gestion-de-facturas">
                  <MenuCard icon={Receipt} title={t("managementInvoice")} />
                </Link>
              </MDBox>
            </Grid>
          )}
          {existPermission("Vacantes") && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <MDBox mb={1.5}>
                <Link to="/referenciados">
                  <MenuCard icon={PersonAdd} title={t("referenced")} />
                </Link>
              </MDBox>
            </Grid>
          )}
          {existPermission("Reservas") && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <MDBox mb={1.5}>
                <Link to="/gestion-de-reservas">
                  <MenuCard icon={House} title={t("managementReservation")} />
                </Link>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </>
  );
};
