import React from 'react';
import { formatDate } from '../../../utils/getFormatDate';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography,
    Divider,
    Chip,
    Box,
} from '@mui/material';

const ReservationDetailModal = ({ open, onClose, reservation, t }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    gutterBottom
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                    {t('reservationDetails')}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                {reservation && (
                    <Grid container spacing={3}>
                        {/* Información General */}
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                gutterBottom
                                sx={{ color: 'primary.main' }}
                            >
                                {t('generalInformation')}
                            </Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Box>
                                <Typography sx={{ marginBottom: 1 }}>
                                    <strong>{t('property')}:</strong>{' '}
                                    {reservation.property}
                                </Typography>
                                <Typography sx={{ marginBottom: 1 }}>
                                    <strong>{t('startDate')}:</strong>{' '}
                                    {formatDate(reservation.startDate, t('invalidDate'))}
                                </Typography>
                                <Typography sx={{ marginBottom: 1 }}>
                                    <strong>{t('endDate')}:</strong>{' '}
                                    {formatDate(reservation.endDate, t('invalidDate'))}
                                </Typography>
                                <Typography sx={{ marginBottom: 1 }}>
                                    <strong>{t('numberOfGuests')}:</strong>{' '}
                                    {reservation.totalPersonas}
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Estados en dos columnas */}
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                gutterBottom
                                sx={{ color: 'primary.main' }}
                            >
                                {t('statusAndApproval')}
                            </Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Grid container spacing={2}>
                                {/* Columna 1: Estado */}
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            <strong>{t('approvalStatusCEO')}:</strong>
                                        </Typography>
                                        <Chip
                                            label={reservation.status || t('unknown')}
                                            color={
                                                reservation.status === 'Pendiente Revisión CEO'
                                                    ? 'warning'
                                                    : reservation.status === 'Aprobado CEO'
                                                        ? 'success'
                                                        : 'error'
                                            }
                                            sx={{
                                                fontWeight: 'bold',
                                                borderRadius: 2,
                                                padding: '4px 8px',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                {/* Columna 2: Aprobación del Gerente */}
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography sx={{ marginBottom: 1 }}>
                                            <strong>{t('approvalStatus')}:</strong>
                                        </Typography>
                                        <Chip
                                            label={
                                                reservation.informacionAprobacionGerente ||
                                                t('unknown')
                                            }
                                            color={
                                                reservation.informacionAprobacionGerente ===
                                                    'Pendiente Revisión Gerente'
                                                    ? 'warning'
                                                    : reservation.informacionAprobacionGerente ===
                                                        'Aprobado Gerente'
                                                        ? 'success'
                                                        : 'error'
                                            }
                                            sx={{
                                                fontWeight: 'bold',
                                                borderRadius: 2,
                                                padding: '4px 8px',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReservationDetailModal;
