import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    Button,
} from '@mui/material';
import { t } from 'i18next';
import calculateNights from '../../../utils/calculateNights';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress } from "@mui/material";

const RenderStepContent = ({
    step,
    reservationData,
    setReservationData,
    validateFields,
    error,
    fieldErrors,
    setFieldErrors,
    isEditMode,
    locations,
    availableRooms,
    setError,
    fetchAvailableRooms,
    maxGuestsAllowed,
    validateDates,
    setAvailableRooms,
    isCheckingAvailability,
    setIsCheckingAvailability,
}) => {
    const selectedLocation = locations.find(
        (location) => location.id === reservationData.location
    );

    switch (step) {
        case 0:
            return (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {t('selectLocation')}:
                    </Typography>
                    <Grid container spacing={2}>
                        {locations.map((location) => (
                            <Grid item xs={12} sm={6} key={location.id}>
                                <Card
                                    onClick={() =>
                                        setReservationData({ ...reservationData, location: location.id })
                                    }
                                    sx={{
                                        cursor: 'pointer',
                                        border:
                                            reservationData.location === location.id
                                                ? '2px solid #1976d2'
                                                : '1px solid #ccc',
                                        boxShadow:
                                            reservationData.location === location.id
                                                ? '0px 0px 10px rgba(25, 118, 210, 0.5)'
                                                : 'none',
                                        transition: '0.3s',
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h6" align="center">
                                            {location.name}
                                        </Typography>
                                        <Typography variant="body2" align="center">
                                            {location.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="body2" sx={{ mt: 2 }}>
                        {t('minimumNightsRequired')}
                        <br />
                        {t('maximumDaysNoGuests')}
                        <br />
                        {t('maximumDaysWithGuests')}
                    </Typography>

                    {(validateFields && !reservationData.location) ? (
                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
                            <Typography color="error" variant={"body2"}>
                                {validateFields && !reservationData.location ? t('errorSelectLocation') : ''}
                            </Typography>
                        </Box>
                    ) : null}

                </Box>
            );

        case 1:
            return (
                <Box>
                    <Typography variant="h6">{t('selectDatesAndGuests')}:</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('selectStartDate')}
                                type="date"
                                fullWidth
                                value={reservationData.startDate}
                                onChange={(e) => {
                                    setReservationData({
                                        ...reservationData,
                                        startDate: e.target.value,
                                        isAvailabilityChecked: false,
                                    });
                                    if (fieldErrors.startDate) {
                                        setFieldErrors({ ...fieldErrors, startDate: '' });
                                    }
                                    setError('');
                                    setAvailableRooms([]);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ mt: 2 }}
                                required
                                error={Boolean(fieldErrors.startDate)}
                                helperText={fieldErrors.startDate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('selectEndDate')}
                                type="date"
                                fullWidth
                                value={reservationData.endDate}
                                onChange={(e) => {
                                    setReservationData({
                                        ...reservationData,
                                        endDate: e.target.value,
                                        isAvailabilityChecked: false,
                                    });
                                    if (fieldErrors.endDate) {
                                        setFieldErrors({ ...fieldErrors, endDate: '' });
                                    }
                                    setError('');
                                    setAvailableRooms([]);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ mt: 2 }}
                                required
                                error={Boolean(fieldErrors.endDate)}
                                helperText={fieldErrors.endDate}
                            />
                        </Grid>

                        {reservationData.isAvailabilityChecked && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label={t('numberOfGuests')}
                                    type="number"
                                    fullWidth
                                    value={reservationData.numberOfGuests}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value, 10);
                                        if (value >= 0 && value <= maxGuestsAllowed) {
                                            setReservationData({ ...reservationData, numberOfGuests: value });
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: 0, max: maxGuestsAllowed }}
                                    required
                                    helperText={fieldErrors.numberOfGuests || t('maxGuestsAllowed', { maxGuests: maxGuestsAllowed })}
                                    error={Boolean(fieldErrors.numberOfGuests)}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                    setError('');
                                    const isValid = await validateDates();
                                    if (!isValid) {
                                        return;
                                    }
                                    setIsCheckingAvailability(true);
                                    const hasAvailability = await fetchAvailableRooms();
                                    setIsCheckingAvailability(false);
                                    if (!hasAvailability) {
                                        return;
                                    }
                                }}
                                disabled={!reservationData.startDate || !reservationData.endDate || isCheckingAvailability}
                            >
                                {isCheckingAvailability ? (
                                    <>
                                        <CircularProgress size={24} color="inherit" />
                                        &nbsp;
                                        {t('checkingAvailability')}
                                    </>
                                ) : (
                                    t('checkAvailability')
                                )}
                            </Button>

                            {reservationData.isAvailabilityChecked && availableRooms.length > 0 && (
                                <Typography variant="body2" sx={{ mt: 2 }}>
                                    {t('availableRoomsCount', { count: availableRooms.length })}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    {error && (
                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
                            <Typography color="error" variant="body2">
                                {error}
                            </Typography>
                        </Box>
                    )}

                    <Backdrop open={isCheckingAvailability} style={{ zIndex: 9999 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            );

        case 2:
            return (
                <Box>
                    <Typography variant="h6">{t('selectRooms')}:</Typography>
                    {availableRooms.length > 0 ? (
                        <TextField
                            label={t('numberOfRooms')}
                            type="number"
                            fullWidth
                            value={reservationData.numberOfRooms}
                            onChange={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (value >= 1 && value <= availableRooms.length) {
                                    setReservationData({
                                        ...reservationData,
                                        numberOfRooms: value,
                                    });
                                } else if (e.target.value === '') {
                                    setReservationData({ ...reservationData, numberOfRooms: '' });
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ mt: 2 }}
                            inputProps={{ min: 1, max: availableRooms.length }}
                            required
                            error={
                                validateFields && !reservationData.numberOfRooms
                            }
                            helperText={
                                validateFields && !reservationData.numberOfRooms
                                    ? t('errorNumberOfRooms')
                                    : t('availableRoomsCount', { count: availableRooms.length })
                            }
                        />
                    ) : (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {t('noRoomsAvailable')}
                        </Typography>
                    )}
                    {error && (
                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
                            <Typography color="error" variant={"body2"}>
                                {error}
                            </Typography>
                        </Box>
                    )}
                </Box>
            );

        case 3:
            const nights = calculateNights(
                reservationData.startDate,
                reservationData.endDate
            );

            return (
                <Box>
                    <Typography variant="h6">{t('confirmation')}</Typography>
                    <Typography>
                        {t('location')}: {selectedLocation ? selectedLocation.name : t('unknown')}
                    </Typography>
                    <Typography>
                        {t('startDate')}: {reservationData.startDate}
                    </Typography>
                    <Typography>
                        {t('endDate')}: {reservationData.endDate}
                    </Typography>
                    <Typography>
                        {t('numberOfGuests')}: {reservationData.numberOfGuests}
                    </Typography>
                    <Typography>
                        {t('numberOfRooms')}: {reservationData.numberOfRooms}
                    </Typography>
                    <Typography>
                        {t('totalNights')}: {nights}
                    </Typography>
                    {isEditMode && (
                        <Typography>
                            {t('status')}: {t(reservationData.status)}
                        </Typography>
                    )}
                </Box>
            );

        default:
            return t('unknownStep');
    }
};

export default RenderStepContent;