import { useState } from "react";
import { useForm } from "react-hook-form";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { InputController, SelectController } from "../../../../components/CustomInput";
import UploadFile from "../../../../examples/UploadFile";
import { CustomAxios } from "../../../../custom/axiosCustom";
import { ErrorAlert, SuccessAlert } from "../../../../custom/SwalCustom";
import { Config } from "../../../../utils/configHeader";

const validationFileInitial = {
    required: false,
    type: false,
    size: false
}

const regexText = /^([a-zA-Z ]+)$/

const DAYS = [
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
    'Domingo',
]

export const DialogPostulateCandidateComponent = ({ handleClose, open, vacancyId, notLoading, loading, t }) => {
    const schema = yup.object().shape({
        firstName: yup.string().required(t("isRequired")).max(30, t("lengthMustNotBeMoreThan30Characters")).min(2, t("lengthMustNotBeGreaterThan2Characters")).matches(regexText, t("itMustContainTextOnly")),
        lastName: yup.string().required(t("isRequired")).max(30, t("lengthMustNotBeMoreThan30Characters")).min(2, t("lengthMustNotBeGreaterThan2Characters")).matches(regexText, t("itMustContainTextOnly")),
        email: yup.string().email(t("mailValid")).required(t("isRequired")),
        interviewDay: yup.string().required(t("isRequired")),
        interviewHour: yup.string().required(t("isRequired")).max(30, t("lengthMustNotBeMoreThan30Characters")).min(2, t("lengthMustNotBeGreaterThan2Characters"))
    }).required();

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const [file, setFile] = useState()
    const [validateFile, setValidateFile] = useState(validationFileInitial)

    const resetField = () => {
        reset({
            firstName: "",
            lastName: "",
            email: ""
        })
    }

    const validateSizeFile = (files) => {
        if (files.size < 3000000) {
            setValidateFile({
                required: false,
                type: false,
                size: false
            })

            return false
        }
        if (files.size > 3000000) {
            setValidateFile({
                required: false,
                type: false,
                size: true
            })

            return true
        }
    }

    const validateTypeFile = (files) => {
        if (files.type === "application/pdf" || files.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            setValidateFile({
                required: false,
                type: false,
                size: false
            })

            return true
        }
        setValidateFile({
            required: false,
            type: true,
            size: false
        })
        return false
    }

    const requiredFile = () => {
        setValidateFile({
            type: false,
            size: false,
            required: true,
        })
    }

    const handleChangeFile = e => {
        if (e.target.files.length) {
            let isFileType = validateTypeFile(e.target.files[0])
            if (isFileType) {
                let isFileSize = validateSizeFile(e.target.files[0])
                if (!isFileSize) {
                    setFile(e.target.files[0])
                }
            }
            return null
        }
        requiredFile()
    };

    const onClose = () => {
        setFile(null)
        setValidateFile(validationFileInitial)
        resetField()
        handleClose()
    }
    const onRegister = (value) => {
        if (file === undefined) {
            requiredFile()
            return null;
        }

        if (!validateTypeFile(file) && validateSizeFile(file)) {
            return null;
        }

        value["vacancyIds"] = [vacancyId]
        let formData = new FormData();
        formData.append("file", file);
        formData.append('vacancyApplication',
            new Blob([JSON.stringify(value)], {
                type: 'application/json'
            }));

        handleClose()
        loading()

        CustomAxios.post("vacancy-applications", formData, Config()).then(() => {
            notLoading()
            resetField()
            SuccessAlert(t("candidateNomination"), t("doneSuccessfully", { a: "a" })).then()
        }).catch(({ response: { data } }) => {
            notLoading()
            resetField()
            ErrorAlert("Error", data.message).then()
        })
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <form onSubmit={handleSubmit(onRegister)}>
                    <DialogTitle>{t("nominateCandidates")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputController name="firstName" control={control}
                                        type="text"
                                        defaultValue="" label={t("name")} />
                                    {errors.firstName &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.firstName.message}</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputController name="lastName" control={control}
                                        type="text"
                                        defaultValue="" label={t("lastname")} />
                                    {errors.lastName &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.lastName.message}</span>}
                                </FormControl>
                            </Grid> <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputController name="email" control={control}
                                        type="email"
                                        defaultValue="" label={t("mailCandidate")} />
                                    {errors.email &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.email.message}</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" align="center">{t("availabilityForInterview")}</Typography>
                            </Grid>
                            <Grid item xs={6} justifyContent={"center"} alignContent={"center"}>
                                <FormControl fullWidth>
                                    <SelectController label={t("weekdayAvailability")}
                                        name="interviewDay"
                                        listValue={DAYS}
                                        control={control}
                                        defaultValue=""
                                    />
                                    {errors.interviewDay &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.interviewDay.message}</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputController name="interviewHour" control={control}
                                        type="text"
                                        defaultValue="" label={t("timeAvailability")} />
                                    {errors.interviewHour &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.interviewHour.message}</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={2}>
                                    <UploadFile
                                        id="coverPage"
                                        nameInput="cover"
                                        onChangeFile={handleChangeFile}
                                        file={file}
                                        textButton={t("uploadCV")}
                                        fileLoaded={t('uploadedResume')}
                                    />
                                    {validateFile.size && <span style={{ fontSize: 12, color: "red" }}>{t("maximumFileSizeInvoice")}</span>}
                                    {validateFile.type && <span style={{ fontSize: 12, color: "red" }}>{t("allowedFormatInvoice")}</span>}
                                    {validateFile.required &&
                                        <span style={{ fontSize: 12, color: "red" }}>{t("isRequired")}</span>}
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>{t("cancel")}</Button>
                        <Button type="submit">{t("postulate")}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
