import React, {useEffect, useState} from "react";

import {useParams} from 'react-router-dom';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {Box, CircularProgress, Typography} from "@mui/material";
import {Check, PriorityHigh} from "@mui/icons-material";

import MDBox from "../../components/MDBox";
import BasicLayout from "../authentication/components/BasicLayout";
import bgImage from "../../assets/images/bg-sign-up-cover.jpeg";
import Dominion from "../../assets/images/logos/logo.svg"
import url from "../../config";
import {setLayout, useMaterialUIController} from "../../context";
import {FormHourComponent} from "./component/formHourComponent";
import {CustomAxios} from "../../custom/axiosCustom";

const Basic = () => {
    localStorage.setItem("token", "")
    const [open, setOpen] = useState(false)
    const {id, token, projectName, localDate, type} = useParams();
    const [error, setError] = useState(false)
    const [, dispatch] = useMaterialUIController();

    const ShowIcons = () => {
        if (!open && !error) {
            return (
                <>
                    <Check color="success" fontSize='large'/>
                    <Typography>Tus horas fueron cargadas con exito</Typography>
                </>
            )
        }
        if (!open && error) {
            return (
                <>
                    <PriorityHigh color="error" fontSize='large'/>
                    <Typography>Este link ya fue utilizado</Typography>
                </>
            )
        }
        return <></>
    }

    useEffect(() => {
        setLayout(dispatch, "pages")
    }, []);

    useEffect(() => {
        if (type === "Automatic") {
            setOpen(true)
            const request = {
                id,
                token,
                projectName,
                localDate,
                type
            }
            CustomAxios.post(`time-entry/charge-hour`, request).then(() => {
                setOpen(false)
            }).catch((error) => {
                setOpen(false)
                setError(true)
            })
        }
    }, [type]);


    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    bgColor="black"
                    borderRadius="lg"
                    coloredShadow="grey"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <Grid container spacing={2} justifyContent="center" sx={{mt: 1, mb: 2}}>
                        <img alt="image" src={Dominion} height="90%" width="90%"/>
                    </Grid>
                </MDBox>
                {type === "Automatic" ? (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                         height="300px">
                        {open && <><CircularProgress/><Typography>Cargando...</Typography></>}
                        <ShowIcons/>
                    </Box>
                ) : (
                    <FormHourComponent/>
                )}

            </Card>
        </BasicLayout>
    )
}

export default Basic;
