import React, { useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ChangeLanguageComponent() {
    const { t, i18n: { changeLanguage, language } } = useTranslation();
    const setLanguage = (value) => {
        changeLanguage(value);
        localStorage.setItem("language", value);
    }

    const onChangeLanguaje = (value) => {
        setLanguage(value.target.value);
    }

    useEffect(() => {
        let languageSave = localStorage.getItem("language")
        if (languageSave && languageSave !== language) {
            setLanguage(languageSave)
        }
    }, [])

    return (
        <>
            <FormControl sx={{ width: "100%" }}>
                <InputLabel id="select-language">{t("language")}</InputLabel>
                <Select label={t("language")} labelId="select-language" value={language} onChange={onChangeLanguaje}>
                    <MenuItem value={"es"}>{t("es")}</MenuItem>
                    <MenuItem value={"en"}>{t("en")}</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}
