import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import {Home, AccountCircle, Notifications, Widgets, MenuOpen} from "@mui/icons-material";
import {Box, Badge, CircularProgress, Divider, MenuItem, Typography} from "@mui/material";
import Icon from "@mui/material/Icon";
import axios from "axios";
import { useTranslation } from "react-i18next";

import MDBox from "../../../components/MDBox";

// Material Dashboard 2 React example components
import Breadcrumbs from "../../../examples/Breadcrumbs";
import NotificationItem from "../../../examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "./styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav
} from "../../../context";
import PropTypes from "prop-types";
import url from "../../../config";
import {BeareToken, Config} from "../../../utils/configHeader";
import TranslateComponent from "../../../layouts/i18n/components/TranslateComponent";

function DashboardNavbar({ absolute, light, isMini }) {
  const {t}=useTranslation();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode, miniSidenav } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [notificationLoading, setNotificationLoading] = useState(false)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    setNotificationLoading(true)
    axios.get(`${url}notifications/new/all`, Config())
        .then(({status, data}) => {
          if (status === 200) {
            setNotificationLoading(false)
            setNotifications(data)
          }
        })
  }, [])

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const onViewed = (id) => {
    setNotificationLoading(true)
    axios.patch(`${url}notifications/${id}/mark-as-viewed`,{}, {
      headers: {
        Authorization: BeareToken()
      }
    })
        .then((response) => {
          if (response.status === 200) {
            axios.get(`${url}notifications/new/all`, Config())
                .then(({status, data}) => {
                  if (status === 200) {
                    setNotificationLoading(false)
                    setNotifications(data)
                  }
                }).catch(error => {
              setNotificationLoading(false)
              console.log(error)
            })
          }
        })
  }

  const renderMenu = () => (
      <Menu
          anchorEl={openMenu}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(openMenu)}
          onClose={handleCloseMenu}
          sx={{mt: 2}}
          style={{overflow: 'thin', height: '500px'}}
      >
        <Box sx={{
          padding: 1
        }}>
          <Typography justifyContent="center" alignContent="center" variant="h4">
            {t("notifications")}
          </Typography>
        </Box>
        <Divider/>
        {notificationLoading && (
            <MenuItem>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
                margin: "auto",
                width: 300,
                height: 150
              }}>
                <CircularProgress/>
              </Box>
            </MenuItem>
        )}
        {!notificationLoading && notifications.map((notification, index) => (
            <NotificationItem closeAction={() => {
              onViewed(notification.id)
            }} key={index} notification={notification}/>
        ))}
        {notifications.length === 0 && (
            <MenuItem style={{cursor: "default"}}>
              <MDBox display="flex" py={0.8} alignItems="center" lineHeight={1} width="370px">
                <Typography variant="button" sx={{ml: 1}} style={{whiteSpace: "break-spaces", fontWeight: "bold", fontSize: "0.8rem"}}>
                  {t("notNewNotifications")}
                </Typography>
              </MDBox>
            </MenuItem>
        )}
      </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(
          theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon={<Home/>} title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/perfil">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <AccountCircle sx={iconsStyle}/>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? <MenuOpen/> : <Widgets/>}
                </Icon>
              </IconButton>
              <TranslateComponent/>
              <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
              >
                <Badge badgeContent={notifications.length} color="primary">
                  <Notifications fontSize="medium" sx={iconsStyle}/>
                </Badge>
              </IconButton>
              
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
