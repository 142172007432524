import { useEffect, useState } from "react";

import { AttachMoney, PersonAdd } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import MDBox from "../../components/MDBox";
import { MainLayout } from "../layout/MainLayout";
import { useLoading } from "../../hooks/useLoading";
import { CustomAxios } from "../../custom/axiosCustom";
import { Config } from "../../utils/configHeader";
import { ErrorServer } from "../../custom/SwalCustom";
import { ReferredCommissionSummaryComponent } from "./component/ReferredCommissionSummaryComponent";
import MenuCard from "../../examples/Cards/MenuCards";
import { dollarUS } from "../../utils/moneyFormat";

export const ReferredCommissionView = () => {
    const { t, i18n: { language } } = useTranslation();
    const { loading, thisLoading, notLoading } = useLoading();
    const [data, setData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0.0);
    const [totalReferred, setTotalReferred] = useState(0);

    const reloadInitial = () => {
        thisLoading()
        CustomAxios.get("commission-payments", Config()).then(({ data }) => {
            setData(data);
            setTotalAmount(data.reduce(
                (previousValue, entry) => previousValue + entry.amount,
                0.0
            ));
            const hiredReferredArray = Array.from(new Set(data.map(entry => entry.candidateEmail)));
            setTotalReferred(hiredReferredArray.length);
            notLoading()
        }).catch(() => {
            notLoading()
            ErrorServer().then()
        })
    }

    useEffect(() => {
        reloadInitial()
    }, []);


    return (
        <MainLayout loading={loading}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                    <MDBox mb={1.5}>
                        <MenuCard
                            icon={PersonAdd}
                            title={t("hiredReference") + ": " + totalReferred}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                    <MDBox mb={1.5}>
                        <MenuCard
                            icon={AttachMoney}
                            title={t("totalChargedForReferrals") + ": " + dollarUS.format(totalAmount)}
                        />
                    </MDBox>
                </Grid>
            </Grid>
            <MDBox py={3}>
                <ReferredCommissionSummaryComponent
                    thisLoading={thisLoading}
                    notLoading={notLoading}
                    data={data}
                    t={t}
                    language={language}
                />
            </MDBox>
        </MainLayout>
    )
}
