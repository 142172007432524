export const locations = [
    {
        id: 'panama',
        name: "Coderland Home Panamá",
        description: "Panamá - 3 cuartos (2 para 2 personas, 1 para 3 personas)",
        rooms: 3,
        capacities: [2, 2, 3],
    },
    {
        id: 'malaga',
        name: "Coderland Home Málaga",
        description: "Málaga - 3 cuartos (cada uno para 2 personas)",
        rooms: 3,
        capacities: [2, 2, 2],
    },
];

/**
 * Steps in the reservation flow process.
 * @type {string[]}
 */
export const steps = ['selectLocation', 'selectDatesAndGuests', 'selectRooms', 'confirmation'];