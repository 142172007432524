import { useEffect, useState } from "react";

import { Box, Card, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { es } from "date-fns/locale";

import MDBox from "../../../../components/MDBox";
import { TableDetailsComponent } from "../pre-invoice/TableDetailsComponent";
import { MainLayout } from "../../../layout/MainLayout";
import { useLoading } from "../../../../hooks/useLoading";
import { CustomAxios } from "../../../../custom/axiosCustom";
import { Config } from "../../../../utils/configHeader";


export const InvoiceDetailsComponent = () => {
    const { t, i18n: {language} } = useTranslation();
    const { loading, thisLoading, notLoading } = useLoading()
    const [invoiceSubmissions, setInvoiceSubmissions] = useState()
    const [invoice, setInvoice] = useState()
    const [detailsInvoice, setDetailsInvoice] = useState([])
    const { invoiceSubmissionId } = useParams()

    useEffect(() => {
        thisLoading()
        CustomAxios.get(`invoice-submissions/${invoiceSubmissionId}`, Config()).then(({ data }) => {
            console.log("dta", data)
            setDetailsInvoice(data.invoice.detailList)
            setInvoice(data.invoice)
            setInvoiceSubmissions(data)
            notLoading()
        }).catch()
    }, [])

    return (
        <MainLayout loading={loading}>
            <MDBox py={3}>
                <Card>
                    <MDBox p={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5">{t("invoice").toUpperCase()}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6">{t("name")}: </Typography>
                                    <Typography variant="p" sx={{ fontSize: 15 }}>{invoice?.consultantName}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6">{t("number")}: </Typography>
                                    <Typography variant="p" sx={{ fontSize: 15 }}>{invoiceSubmissions?.invoiceNumber}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6"> {t("numberPassPort")}: </Typography>
                                    <Typography variant="p" sx={{ fontSize: 15 }}>{invoice?.consultantPassportNumber}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6"> {t("city")}: </Typography>
                                    <Typography variant="p" sx={{ fontSize: 15 }}>{invoice?.consultantCity}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6">{t("creationDate")}: </Typography>
                                    <Typography variant="p" sx={{ fontSize: 15 }}>{invoice?.sent === undefined ? '' : format(parseISO(invoice?.sent), 'dd/MM/yyyy')}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6"> {t("country")}: </Typography>
                                    <Typography variant="p" sx={{ fontSize: 15 }}>{invoice?.consultantCountry}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6"> {t("period")}: </Typography>
                                    <Typography variant="p" sx={{ fontSize: 15 }}>{invoiceSubmissions?.invoiceDate === undefined ? '' : format(parseISO(invoiceSubmissions?.invoiceDate), 'dd/MM/yyyy')}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TableDetailsComponent details={detailsInvoice} t={t} language={language}/>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Card>
            </MDBox >
        </MainLayout >
    )
}
