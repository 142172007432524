import {useEffect, useState, Suspense} from "react";

import {Box, Container, Grid, Typography} from "@mui/material";
import DocViewer, {DocViewerRenderers} from "react-doc-viewer";
import i18next from "i18next";

import {MainLayout} from "../layout/MainLayout";
import MDBox from "../../components/MDBox";
// import Manifest from "../../assets/images/ModuloCMS/PaginaPrincipal/Manifiesto_de_Cultura.pdf";
import {LinkUtilsComponent} from "../dashboard/components/linkUtilsComponent";
import {CustomAxios} from "../../custom/axiosCustom";
import {Config} from "../../utils/configHeader";
import {useUserData} from "../../hooks/useUserData";
import {CoverComponent} from "./component/people-and-culture/component/coverComponent";
import {ReferredComponent} from "./component/people-and-culture/component/referredComponent";
import {getUrlFile} from "../../utils/setting";
import { useLoading } from "../../hooks/useLoading";

export const PeopleAndCulture = () => {
    const {loading, notLoading, thisLoading} = useLoading()
    const [docs, setDocs] = useState([])
    const {userSetting} = useUserData()
    const [imageServer, setImageServer] = useState(null)
    
    useEffect(() => {
        if (userSetting !== null) {
            if (userSetting.viewPeopleCultureStart) {
                CustomAxios.get(`user-setting`, Config()).then()
            }
        }
    }, [userSetting])

    useEffect(() => {
        thisLoading()
        CustomAxios.get(`cms/people-culture`, Config()).then(({data}) => {
            if(data !== ""){
                let imageHomePage = (data.imageHomePage)
                let imageCover = data.imageCover.find((image) => image.fileName === '07_Cover_PP.jpg')
                let Manifest = imageHomePage.find(file => file.fileName === '01__PP_Manifiesto Cultura.pdf')
                imageHomePage.push(imageCover)
                const doc = getUrlFile(Manifest.fileId, Manifest.extension)
                setDocs([{uri: doc, fileType: 'pdf'}])
                setImageServer(imageHomePage)
            }
        }).finally(notLoading)
    }, [])

    const getFile = (fileName) => {
        if (imageServer !== null) {
            const value = imageServer.find((image) => image.fileName === fileName);
            if(value === undefined || value === null){
                return "";
            }
            return getUrlFile(value.fileId, value.extension);
        }
    }


    return (
        <MainLayout loading={loading}>
            <Container>
                {
                    imageServer && <CoverComponent getFile={getFile} fileName="07_Cover_PP.jpg" name="People & Culture"/>
                }
                <MDBox py={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align="center" variant="h2">
                               {i18next.t("cultureManifesto")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: "20px"}}>
                            <Box style={{backgroundColor: "white"}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Container>
                                            <Typography variant="p" align="justify">
                                               {i18next.t("peopleAndCultureMessage")}
                                            </Typography>
                                        </Container>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DocViewer
                                            pluginRenderers={DocViewerRenderers}
                                            documents={docs}
                                            config={{
                                                header: {
                                                    disableHeader: false,
                                                    disableFileName: true,
                                                    retainURLParams: false,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <Container>
                                                    <Box sx={{padding: "50px"}}>
                                                        <Typography variant="h4" align="center">
                                                            {i18next.t("birthdayMonth")}
                                                        </Typography>
                                                        <Typography variant="p" align="justify">
                                                           {i18next.t("birthdayMonthMessage")}
                                                        </Typography>
                                                        <img src={getFile("02_PP_cumple.png")}
                                                             style={{width: "100%"}}/>
                                                    </Box>
                                                </Container>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <Container>
                                                    <Box sx={{padding: "50px"}}>
                                                        <LinkUtilsComponent/>
                                                    </Box>
                                                </Container>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <ReferredComponent getFile={getFile}/>
                    </Grid>
                </MDBox>
            </Container>
        </MainLayout>
    );
};
