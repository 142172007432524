import {useState} from "react";

export const useLoading = () =>{
    const [loading, setLoading] = useState()

    const notLoading = () => {
        setLoading(false)
    }

    const thisLoading = () => {
        setLoading(true)
    }

    return{
        loading,
        notLoading,
        thisLoading
    }
}
