const titleSupport = 'Soporte de plataforma'
const contentSupport = '  Por este medio puedes enviarnos tus sugerencias, problemas y comentarios respecto a la plataforma. por parte nuestro\n' +
    '                        equipo estaremos encantado de responder cualquier duda.'
const titleSupportVacancy = 'Contactar con recursos humanos '
const contentSupportVacancy = '  Por este medio puedes enviarnos tus sugerencias, problemas y comentarios respecto a las vacantes. por parte nuestro\n' +
    '                        equipo estaremos encantado de responder cualquier duda.'

export {
    titleSupport,
    contentSupport,
    titleSupportVacancy,
    contentSupportVacancy
}
