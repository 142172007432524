import {Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import {TextField} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {NumberFormatCustom} from "../../../examples/NumberFormat";

const InputNumberFormatController = ({control, name, label, defaultValue}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field}) => (
                <FormControl fullWidth>
                    <TextField
                        label={label}
                        margin="dense"
                        value=""
                        fullWidth
                        {...field}
                        name={name}
                        id="formatted-numberformat-input"
                        variant="outlined"
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                </FormControl>
            )}
        />
    )
}

InputNumberFormatController.prototype = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    control: PropTypes.element.isRequired,
    defaultValue: PropTypes.any.isRequired

}

export default InputNumberFormatController;
