import React, { useEffect, useState } from "react";

import { useNavigate } from 'react-router-dom';
import { FormControl, Card, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import BasicLayout from "../components/BasicLayout";
import bgImage from "../../../assets/images/sign_in_image_background.jpg";
import Dominion from "../../../assets/images/logos/logo.svg"
import Loading from "../../../examples/loading";
import SnackbarComponent from "../../../components/Snackbar";
import { CustomAxios } from "../../../custom/axiosCustom";
import { setLayout, useMaterialUIController } from "../../../context";
import { useLoading } from "../../../hooks/useLoading";
import { Config } from "../../../utils/configHeader";
import { InfoAlert, WarningAlert, WarningAlertCustom } from "../../../custom/SwalCustom";
import ChangeLanguageComponent from "../../i18n/components/ChangeLanguageComponent";



const Basic = () => {
    const { t } = useTranslation();
    localStorage.setItem("token", "")
    const { loading, notLoading, thisLoading } = useLoading()
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [message, setMessage] = useState('')
    const [, dispatch] = useMaterialUIController();

    const { handleSubmit, control, formState: { errors } } = useForm({ mode: "onChange" });

    function invoiceUnsentAlert(checkUnsentInvoice) {
        if (checkUnsentInvoice === 'Found') WarningAlert(t("warningTitle"), t("invoiceUnsent"));
    }

    function outdatedUserInformationAlert(language) {
        Swal.fire({
            title: t('outdatedUserInformationTitle'),
            text: t('outdatedUserInformationMessage'),
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: t('updateInformation'),
        }).then((res) => {
            if (res.isConfirmed) {
                language == "Español" 
                ? window.open('https://forms.gle/brGu9CauamJPGtes9','_blank') 
                : window.open('https://forms.gle/5xe33RZgH9xvQYUQ7','_blank')
            }
        })
    } 

    useEffect(() => {
        setLayout(dispatch, "pages")
    }, []);

    const navigate = useNavigate();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const onSignIn = async (value) => {
        thisLoading()
        try {
            const { data } = await CustomAxios.post(`auth/signin`, value)
            localStorage.setItem("user", JSON.stringify(data))
            localStorage.setItem("token", JSON.stringify(data.token))
            const checkUnsentInvoiceResponse = await CustomAxios.get(`invoices/check-unsent`, Config())
            if (new Date().getDay() === 2 || new Date().getDay() === 5){
                const validateHourCharged = await CustomAxios.get(`time-entry/validate-timesheet/week-current`, Config())
                if(validateHourCharged.status === 200){
                    if(validateHourCharged.data.isChargedHourInWeek){
                        InfoAlert(t("weeklyHoursTitle"), validateHourCharged.data.message).then(() => {
                            invoiceUnsentAlert(checkUnsentInvoiceResponse.data);
                        })
                    }else{
                        const template = `<p>${validateHourCharged.data.message}</p><br/><ul>${validateHourCharged.data.hourNotCharged.map(item =>  `<li>${item}</li>`).join("")}</ul>`
                        WarningAlertCustom(t("warningTitle"), template).then(() => {
                            invoiceUnsentAlert(checkUnsentInvoiceResponse.data);
                        })
                    }
                }
            }else{
                invoiceUnsentAlert(checkUnsentInvoiceResponse.data);
            }
            const checkInfoProfileResponse = await CustomAxios.get(`user-profile/validate-info-profile`, Config())
            if(checkInfoProfileResponse.status === 200){
                if(!checkInfoProfileResponse.data.hasCompleteInformation){
                    outdatedUserInformationAlert(checkInfoProfileResponse.data.language)
                }
            }
            navigate("/people-and-culture")     
        } catch (error) {
            if (!!error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage(t('internalError'));
            }
            setOpenSnackbar(true)
        } finally { notLoading() }
    }

    return (
        <BasicLayout image={bgImage}>
            <SnackbarComponent
                open={openSnackbar}
                handleClose={handleClose}
                message={message}
                alert="error"
            />
            <Loading open={loading} />
            <Card>
                <MDBox
                    bgColor="black"
                    borderRadius="lg"
                    coloredShadow="grey"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                        <img alt="image" src={Dominion} width="90%" height="90%" />
                    </Grid>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <form onSubmit={handleSubmit(onSignIn)}>
                        <MDBox mb={2}>
                            <Controller
                                name="username"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <FormControl fullWidth>
                                        <TextField
                                            label={t("usernameField")}
                                            margin="dense"
                                            value=""
                                            fullWidth
                                            {...field}
                                            type="text"
                                            name="username"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                )}
                            />
                            {errors.username && errors.username.type === "required" &&
                                <span style={{ fontSize: 12, color: "red" }}>{t('isRequired')}</span>}
                        </MDBox>
                        <MDBox mb={2}>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <FormControl fullWidth>
                                        <TextField
                                            label={t("passwordField")}
                                            margin="dense"
                                            value=""
                                            type="password"
                                            fullWidth
                                            {...field}
                                            name="password"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                )}
                            />
                            {errors.password && errors.password.type === "required" &&
                                <span style={{ fontSize: 12, color: "red" }}>{t('isRequired')}</span>}
                        </MDBox>
                        <MDBox mb={2}>
                            <ChangeLanguageComponent />
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" type="submit" color="info" fullWidth>
                                {t('loginButton')}
                            </MDButton>
                        </MDBox>
                    </form>

                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Basic;
