import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Loading from "../../examples/loading";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

export const MainLayout = ({loading, children}) => {
    return(
        <DashboardLayout>
            <Loading open={loading}/>
            <DashboardNavbar/>
            {children}
        </DashboardLayout>
    )
}
