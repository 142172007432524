import {FormControl, IconButton, InputBase, Paper, Tooltip} from "@mui/material";
import {RestartAlt, Search} from "@mui/icons-material";
import {Controller} from "react-hook-form";

export const SearchInputComponent = ({control, onSearch, onResetSearch ,t }) => {
    return (
        <Paper
            component="form"
            sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%'}}
        >
            <Tooltip title={t("reset")}>
                <IconButton sx={{p: '10px'}} aria-label="menu" onClick={onResetSearch}>
                    <RestartAlt/>
                </IconButton>
            </Tooltip>
            <Controller
                name="search"
                control={control}
                defaultValue=""
                render={({field}) => (
                    <FormControl fullWidth>
                        <InputBase
                            {...field}
                            sx={{ml: 1, flex: 1}}
                            placeholder={t("searchByVacancies")}
                            inputProps={{'aria-label': 'search google maps'}}
                        />
                    </FormControl>
                )}/>
            <Tooltip title={t("search")}>
                <IconButton type="button" sx={{p: '10px'}} aria-label="search" onClick={onSearch}>
                    <Search/>
                </IconButton>
            </Tooltip>
        </Paper>
    );
}
