import { Button, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";

/**
 * CustomToolbarComponent is a functional component that renders a button with a tooltip.
 * 
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title for the tooltip.
 * @param {Function} props.onClickAction - The function to call when the button is clicked.
 * @param {Function} props.t - The translation function for localization.
 * 
 * @returns {JSX.Element} The rendered component.
 */
export const CustomToolbarComponent = ({ title, onClickAction, t }) => {
  return (
    <Tooltip title={title}>
      <Button variant="outlined" onClick={onClickAction} startIcon={<Add />}>
        {t("register")}
      </Button>
    </Tooltip>
  );
};
