const GetWeekdaysOfMonth = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  let weekdays = [];
  let daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

  for (let day = 1; day <= daysInMonth; day++) {
      let date = new Date(currentYear, currentMonth - 1, day);
      let dayOfWeek = date.getDay();

      // Check if it's a weekday (Monday to Friday)
      if (dayOfWeek > 0 && dayOfWeek < 6) {
          weekdays.push(date);
      }
  }

  return weekdays.length;
}

export { GetWeekdaysOfMonth }