export const CoverComponent = ({getFile, fileName, name}) => {
    return (
        <div style={{position: "relative"}}>
             <img
                style={{height: "100%", paddingTop: "2%", width: "100%", minHeight: "200px", minWidth: "300px"}}
                component="img"
                src={getFile(fileName)}
                title={name}
                alt={name}
            />
        </div>
    )
}
