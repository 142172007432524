import React, { useEffect, useState, Suspense } from 'react';
import { MainLayout } from '../layout/MainLayout';
import MDBox from '../../components/MDBox';
import {
    Box,
    Container,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
} from '@mui/material';
import i18next from 'i18next';
import { CardPage } from './component/CardPage';
import { CustomAxios } from '../../custom/axiosCustom';
import { Config } from '../../utils/configHeader';
import { getUrlFile } from '../../utils/setting';
import { CoverComponent } from './component/people-and-culture/component/coverComponent';
import { usePermission } from '../../hooks/usePermission';
import { userContainPermission } from '../../utils/permission';
import { useLoading } from '../../hooks/useLoading';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate

const ReservationFlow = React.lazy(() => import('../../components/ReservationFlow/ReservationFlow'));

export const BenefistCoderland = () => {
    const { loading, thisLoading, notLoading } = useLoading();
    const [cardsData, setCardsData] = useState(null);
    const [cover, setCover] = useState('');
    const { permissionList } = usePermission('Perfil de Imputacion');
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate(); // Inicializar navigate

    const existPermission = (permission) => {
        return userContainPermission(permission, permissionList);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    // Función que se ejecuta al completar la reserva
    const handleReservationCompleted = (reservationData) => {
        setOpenModal(false); // Cerrar el modal
        navigate('/gestion-de-reservas'); // Navegar a la ruta deseada
    };

    useEffect(() => {
        thisLoading();
        CustomAxios.get(`cms/people-culture`, Config())
            .then(({ data }) => {
                const cover = data.imageCover.find((image) => image.fileName === '05_PP_Cover_BE.png');
                setCover(cover);
                const cardsData = data.benefitsCardsData;
                setCardsData(cardsData);
            })
            .finally(notLoading);
    }, []);

    const getFile = (file) => (file ? getUrlFile(file.fileId, file.extension) : null);

    return (
        <MainLayout loading={loading}>
            <Container>
                {cover && (
                    <CoverComponent getFile={getFile} fileName={cover} name="Beneficios" />
                )}
                <MDBox py={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box>
                                <Grid container spacing={3}>
                                    {cardsData &&
                                        cardsData.map(
                                            (card, index) =>
                                                existPermission(card.permission) && (
                                                    <Grid key={index} item xs={12} md={6} lg={4}>
                                                        <CardPage
                                                            title={card.title}
                                                            image={getFile(card.image)}
                                                            content={card.text}
                                                            textFirst={i18next.t('policies')}
                                                            urlFirst={card.link}
                                                            textSecond={i18next.t('obtain')}
                                                            onSecondClick={
                                                                card.title === "CODERLAND HOME'S"
                                                                    ? handleOpenModal
                                                                    : null
                                                            }
                                                            urlSecond="#/people-and-culture"
                                                        />
                                                    </Grid>
                                                )
                                        )}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </MDBox>

                <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                    <DialogTitle>{i18next.t('coderlandHomeModalTitle')}</DialogTitle>
                    <DialogContent>
                        <Suspense fallback={<CircularProgress />}>
                            <ReservationFlow
                                handleCloseModal={handleCloseModal}
                                mode="create" // Especificar modo creación
                                onReservationCompleted={handleReservationCompleted} // Pasar la función
                            />
                        </Suspense>
                    </DialogContent>
                </Dialog>
            </Container>
        </MainLayout>
    );
};
