import {
    AccessTimeFilled,
    DashboardCustomize, InsertDriveFile,
    Logout, Person, PersonAdd, Receipt, Topic, EmojiPeople, BookOnline, Bookmark, AdminPanelSettings,
    House
} from "@mui/icons-material";

import LogoutComponent from "./layouts/authentication/logout";
import Dashboard from "./layouts/dashboard";
import { ManagementRequestComponent } from "./layouts/management-request/ManagementRequestComponent";
import { ManagementHourView } from "./layouts/management-hour/ManagementHourView";
import { ManagementInvoiceView } from "./layouts/management-invoice/ManagementInvoiceView";
import PrivateRoute from "./PrivateRoute";
import Profile from "./layouts/profile";
import { ReferredCommissionView } from "./layouts/referred-commission/ReferredCommissionView";
import { PeopleAndCulture } from "./layouts/page-info/peopleAndCulture";
import { BenefistCoderland } from "./layouts/page-info/benefist";
import { AdministrationCoderland } from "./layouts/page-info/administration";
import { FormationCoderland } from "./layouts/page-info/formation";
import AdminView from "./layouts/management-admin/AdminView";
import { ManagementReservationComponent } from "./layouts/management-reservation/ManagementReservationComponent";

const routesSidenav = [
    {
        type: "title",
        name: "Inicio",
        key: "inicio",
        icon: <DashboardCustomize fontSize="small" />,
        route: "/inicioss",
        permission: "Default",
        auth: true,
        component: <Dashboard />,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "home",
        key: "inicio",
        icon: <DashboardCustomize fontSize="small" />,
        route: "/inicio",
        permission: "Default",
        auth: true,
        component: <Dashboard />,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "managementInvoice",
        key: "gestion-de-facturas",
        icon: <Receipt fontSize="small" />,
        route: "/gestion-de-facturas",
        auth: true,
        component: <ManagementInvoiceView />,
        permission: "Factura",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "managementApplication",
        key: "solicitudes",
        icon: <Topic fontSize="small" />,
        route: "/solicitudes",
        permission: "Solicitudes",
        auth: true,
        component: <ManagementRequestComponent />,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "managementReservation",
        key: "gestion-de-reservas",
        icon: <House fontSize="small" />,
        route: "/gestion-de-reservas",
        auth: true,
        permission: "Reservas",
        component: <ManagementReservationComponent />,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "managementVacancy",
        key: "gestion-de-vacantes",
        icon: <InsertDriveFile fontSize="small" />,
        route: "/gestion-de-vacantes",
        auth: true,
        permission: "Vacantes",
        component: <ManagementInvoiceView />,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "managementHour",
        key: "gestion-de-horas",
        icon: <AccessTimeFilled fontSize="small" />,
        route: "/gestion-de-horas",
        auth: true,
        permission: "Carga de horas",
        component: <ManagementHourView />,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "referenced",
        key: "referenciados",
        icon: <PersonAdd fontSize="small" />,
        route: "/referenciados",
        permission: "Vacantes",
        auth: true,
        component: <PrivateRoute><ReferredCommissionView /></PrivateRoute>,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "administrativePanel",
        key: "panel-administracion",
        icon: <AdminPanelSettings fontSize="small" />,
        route: "/panel-administrativo",
        auth: true,
        permission: "Administración",
        component: <AdminView />,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "profile",
        key: "perfil",
        icon: <Person fontSize="small" />,
        route: "/perfil",
        permission: "Default",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL', 'TELEFONICA', 'VISUALIZER'],
        component: <PrivateRoute><Profile /></PrivateRoute>,
    },
    {
        type: "divider",
        name: "Perfil",
        key: "divide",
    },
    {
        type: "collapse",
        name: "People & Culture",
        key: "people-and-culture",
        icon: <EmojiPeople fontSize="small" />,
        route: "/people-and-culture",
        permission: "Default",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PeopleAndCulture />,
    },
    {
        type: "collapse",
        name: "administration",
        key: "administracion",
        permission: "Default",
        icon: <BookOnline fontSize="small" />,
        route: "/administracion",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><AdministrationCoderland /></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "training",
        key: "formacion",
        permission: "Default",
        icon: <DashboardCustomize fontSize="small" />,
        route: "/formacion",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><FormationCoderland /></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "benefits",
        key: "beneficios",
        permission: "Default",
        icon: <Bookmark fontSize="small" />,
        route: "/beneficios",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><BenefistCoderland /></PrivateRoute>,
    },
    {
        type: "divider",
        name: "Perfil",
        key: "divideLogout",
    },
    {
        type: "collapse",
        name: "logout",
        key: "logout",
        icon: <Logout fontSize="small" />,
        route: "/logout",
        permission: "Default",
        auth: true,
        component: <LogoutComponent />,
    },

];

export default routesSidenav;
