import {FormControl, Grid} from "@mui/material";
import {InputController} from "../../../../components/CustomInput";
import React from "react";
import i18next from 'i18next';

export const ChargedManualComponent = ({errors, control, defaultValue}) => {
    return(
        <>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputController name="ccEmail" control={control}
                                     type="email"
                                     defaultValue={defaultValue.email} label={i18next.t("ccEmail")}/>
                    {errors.ccEmail &&
                    <span style={{fontSize: 12, color: "red"}}>{errors.ccEmail.message}</span>}
                </FormControl>
            </Grid>
        </>
    )
}
