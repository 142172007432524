import { useState } from "react";
import { Grid } from "@mui/material";
import { Cached, Download } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import MDBox from "../../../../components/MDBox";
import MenuCard from "../../../../examples/Cards/MenuCards";
import { CustomAxios } from "../../../../custom/axiosCustom";
import {
  Config,
  ConfigHeaderBlobResponse,
} from "../../../../utils/configHeader";
import {
  ErrorAlert,
  InfoAlert,
  SuccessAlert,
} from "../../../../custom/SwalCustom";
import { AddDetailComponent } from "./AddDetailComponent";
import { generateInvoiceFunction } from "./GenerateInvoiceFunction";
import { getFormatDate } from "../../../../utils/getFormatDate";

const urlAddItem = "pre-invoices/add-item"
const urlAddItemLastMonth = "pre-invoices/add-item/last-month"
const urlPreInvoicePending = "pre-invoices/pending"
const urlConfirmPreInvoice = "invoices/confirm-pre-invoice/"
const urlSendInvoice = "invoices/send"

export const ItemPreInvoiceComponent = ({ thisLoading, notLoading, getListPreInvoice, userData }) => {
  const {t} = useTranslation();
  const [addItemInvoiceDialog, setAddItemInvoiceDialog] = useState(false);
  const [urlAddItems, setUrlAddItems] = useState(urlAddItem)
  const onActiveAddItemInvoiceDialog = () => setAddItemInvoiceDialog(true);
  const onDisabledAddItemInvoiceDialog = () => setAddItemInvoiceDialog(false);

  const generatePreInvoice = () => {
    setUrlAddItems(urlAddItem)
    CustomAxios.get(`pre-invoices`, Config())
      .then(({ data }) => {
        getListPreInvoice()
        generateInvoiceFunction(
          data,
          thisLoading,
          notLoading,
          onActiveAddItemInvoiceDialog,
          urlPreInvoicePending,
          urlConfirmPreInvoice,
          urlSendInvoice
        );
      })
      .catch(({ response: { data } }) => {
        notLoading();
        if (data.code === 400) {
          ErrorAlert(t("errorToGeneratePreInvoice"), data.message).then();
          return;
        }
        ErrorAlert("Error", t("errorToDownloadPreInvoice")).then();
      });
  }

  const generatePreInvoiceLastMonth = () => {
    thisLoading();
    setUrlAddItems(urlAddItemLastMonth)
    CustomAxios.get(`pre-invoices/create-last-month`, Config())
      .then(({ data }) => {
        getListPreInvoice()
        notLoading();
        generateInvoiceFunction(
          data,
          thisLoading,
          notLoading,
          onActiveAddItemInvoiceDialog,
          urlPreInvoicePending,
          urlConfirmPreInvoice,
          urlSendInvoice
        );
      }).catch(({ response: { data } }) => {
        notLoading();
        if (data.code === 400) {
          ErrorAlert(t("errorToGeneratePreInvoice"), data.message).then();
          return;
        }
        ErrorAlert("Error", t("errorToDownloadPreInvoice")).then();
      });
  }

  const validateHour = () => {
    CustomAxios.get(`pre-invoices/validate-hours`, Config())
      .then(async ({ data }) => {
        if (data.statusCode === 200) {
          await SuccessAlert(data.message).then();
        }
        if (data.statusCode === 100) {
          await InfoAlert(data.message).then();
        }
        if (data.statusCode === 400) {
          await ErrorAlert(data.message).then();
        }
        thisLoading();
        generatePreInvoice()
      }).catch((error) => {
        ErrorAlert("Error",t("errorToValidateLoadHour")).then();
      });
  }

  const generatePreInvoiceInitial = () => {
    thisLoading();
    CustomAxios.get(`pre-invoices/validate-last-month`, Config())
      .then(async ({ data }) => {
        notLoading();
        if (data.statusCode === 200) {
          Swal.fire({
            html: `${data.message} <br><br> ${t("generatePreInvoiceLastMonthQuestion")}`,
            title: t("preInvoiceLastMonthTitle"),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            showDenyButton: true,
            cancelButtonColor: "#d33",
            denyButtonColor: "#049942",
            confirmButtonText: t("confirm"),
            denyButtonText: "No",
            cancelButtonText: t("cancel"),
          }).then(({ isConfirmed, isDenied }) => {
            if (isConfirmed) {
              generatePreInvoiceLastMonth()
            }
            if (isDenied) {
              validateHour()
            }
          });
        }
        if (data.statusCode === 400) {
          validateHour();
        }
      }).catch(({ response: { data } }) => {
        notLoading()
        ErrorAlert(t("errorTitle"), data.message).then()
    })
  };

  const onDownload = () => {
    thisLoading();
    CustomAxios.get(`pre-invoices/current`, Config())
      .then(({ data: { id } }) => {
        CustomAxios.get(
          `pre-invoices/${id}/download`,
          ConfigHeaderBlobResponse()
        )
          .then(({ data, headers }) => {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = `${t("preInvoice")}_${userData.firstName}_${userData.lastName}${getFormatDate()}.pdf`;
            link.href = window.URL.createObjectURL(
              new Blob([data], {
                type: headers["content-type"],
              })
            );
            link.click();
            notLoading();
          })
          .catch(() => notLoading());
      })
      .catch(({ response: { data } }) => {
        notLoading();
        console.log(data);
        if (data.code === 400) {
          InfoAlert(t("errorToDownloadPreInvoice"), data.message).then();
          return;
        }
        ErrorAlert("Error", t("errorToGeneratePreInvoice")).then();
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4} xl={4} onClick={generatePreInvoiceInitial}>
        <MDBox mb={1.5}>
          <MenuCard icon={Cached} title={t("generate")} />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={4} onClick={onDownload}>
        <MDBox mb={1.5}>
          <MenuCard icon={Download} title={t("download")} />
        </MDBox>
      </Grid>
      <Grid item xs={12}>
        <AddDetailComponent
          value={addItemInvoiceDialog}
          onDisabled={onDisabledAddItemInvoiceDialog}
          thisLoading={thisLoading}
          notLoading={notLoading}
          onActive={onActiveAddItemInvoiceDialog}
          urlAddItem={urlAddItems}
          urlPreInvoicePending={urlPreInvoicePending}
          urlConfirmPreInvoice={urlConfirmPreInvoice}
          t={t}
        />
      </Grid>
    </Grid>
  );
};
