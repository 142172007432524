// calculateNights.js

/**
 * Calcula el número de noches entre dos fechas.
 *
 * @param {string} start - La fecha de inicio en un formato reconocido por el constructor Date.
 * @param {string} end - La fecha de fin en un formato reconocido por el constructor Date.
 * @returns {number} El número de noches entre las fechas de inicio y fin.
 */
const calculateNights = (start, end) => {
    try {
        const startDate = new Date(start);
        const endDate = new Date(end);

        if (isNaN(startDate) || isNaN(endDate)) {
            console.error('Fechas inválidas proporcionadas.');
            return 0;
        }

        const utcStart = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const utcEnd = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

        const diffTime = utcEnd - utcStart;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        if (diffDays < 0) {
            console.error('La fecha de fin es anterior a la fecha de inicio.');
            return 0;
        }

        return Math.floor(diffDays);
    } catch (error) {
        console.error('Error al calcular las noches:', error);
        return 0;
    }
};

export default calculateNights;
