import {MainLayout} from "../layout/MainLayout";
import MDBox from "../../components/MDBox";
import {ItemInvoiceComponent} from "./component/itemInvoiceComponent";

export const ManagementInvoiceView = () => {

    return(
        <MainLayout loading={false}>
            <MDBox py={3}>
                <ItemInvoiceComponent/>
            </MDBox>
        </MainLayout>
    )
}
