import {useEffect, useState} from "react";
import {CustomAxios} from "../custom/axiosCustom";
import {Config} from "../utils/configHeader";

export const useUserProfile = (isLoading, isLoaded) => {

    const [userData, setUserData] = useState();
    const [isReady, setReady] = useState(false);

    useEffect(() => {
        if (isReady)
            return;

        if (isLoading && typeof isLoading === 'function')
            isLoading();

        CustomAxios.get(`user-profile`, Config())
            .then(({ data }) => setUserData(data))
            .finally(() => {
                if (isLoaded && typeof isLoaded === 'function')
                    isLoaded();

                setReady(true);
            })
    }, [isLoading, isLoaded, userData, setUserData, isReady, setReady]);

    return { userData, isReady }
}