import {useState, useEffect} from "react";
import { useUserData } from "./useUserData";

export const usePermission = (permission) =>{
    const {permissions} = useUserData()
    const [permissionList, setPermissionList] = useState(null)
    useEffect(() => {
        if (permissions !== undefined && permissions !== null) {
          let result = permissions.find(({ name }) => name === permission);
          setPermissionList(result.permissions);
        }
    }, [permissions]);

    return{
        permissionList
    }
}
