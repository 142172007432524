import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid} from "@mui/material";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import parseISO from "date-fns/parseISO";
import * as yup from "yup";

import {CustomAxios} from "../../../custom/axiosCustom";
import {Config} from "../../../utils/configHeader";
import {ErrorAlert, SuccessAlert} from "../../../custom/SwalCustom";
import { InputController, InputDatePickerController } from "../../../components/CustomInput";
import SelectActivityComponent from "./selectActivityComponent";

export const DialogMonthlyHourComponent = ({handleClose, open, notLoading, loading, t, data, reloadProjectData}) => {
    const [activityList, setActivityList] = useState();
    const schema = yup.object().shape({
        hours: yup.number().required(t("isRequired"))
    }).required();

    const {control, handleSubmit, setValue, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        loading()
        CustomAxios.get(`time-entry/activities`, Config()).then(({ data }) => {
            setActivityList(data)
            notLoading()
        })
    
    }, [open]);

    useEffect(() => {
        if (data) {
            setValue("id", data.id)
            setValue("projectId", data.projectId)
            setValue("projectName", data.projectName)
            setValue("activityId", data.activityId)
            setValue("hours", data.hours)
            setValue("comment", data.comment)
            setValue("spentOn", parseISO(data.spentOn))
        }
        
    }, [data]);

    const onRegister = (value) => {
        handleClose()
        loading()
        CustomAxios.put("time-entry/update-monthly-hours", value, Config()).then(() => {
            notLoading()
            SuccessAlert(t("loadHours"), t("doneSuccessfully", {a: "a"})).then(()=> reloadProjectData())
        }).catch(({response: {data}}) => {
            notLoading()
            handleClose()
            ErrorAlert(t("errorTitle"), data.message).then()
        })
    }

  return (
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <form onSubmit={handleSubmit(onRegister)}>
              <DialogTitle>{t("updateTimeStamp")}</DialogTitle>
              <DialogContent>
                  <Grid container spacing={3}>
                      <Grid item xs={12} id="hours-field">
                          <FormControl fullWidth sx={{ mt: 1 }}>
                              <InputController
                                  control={control}
                                  defaultValue={8}
                                  label={t("project")}
                                  name="projectName"
                                  disabled={true}
                              />
                              {errors.hours &&
                                  <span style={{ fontSize: 12, color: "red" }}>{errors.hours.message}</span>}
                          </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                          <FormControl fullWidth sx={{ mt: 1 }}>
                              <SelectActivityComponent control={control} defaultValue={""}
                                  label={t("activities")}
                                  name="activityId"
                                  listValue={activityList}
                              />
                              {errors.activityId &&
                                  <span style={{ fontSize: 12, color: "red" }}>{errors.activityId.message}</span>}
                          </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                          <FormControl fullWidth>
                              <InputDatePickerController
                                  control={control}
                                  defaultValue={new Date()}
                                  openTo='month'
                                  name="spentOn"
                                  label={t("date")}
                                  views={['year', 'month', 'day']}
                              />
                              {errors.spentOn &&
                                  <span style={{ fontSize: 12, color: "red" }}>{errors.spentOn.message}</span>}
                          </FormControl>
                      </Grid>
                      <Grid item xs={12} id="hours-field">
                          <FormControl fullWidth sx={{ mt: 1 }}>
                              <InputController
                                  control={control}
                                  defaultValue={8}
                                  label={t("hours")}
                                  name="hours"
                                  type="number"
                              />
                              {errors.hours &&
                                  <span style={{ fontSize: 12, color: "red" }}>{errors.hours.message}</span>}
                          </FormControl>
                      </Grid>
                      <Grid item xs={12} id="hours-field">
                          <FormControl fullWidth sx={{ mt: 1 }}>
                              <InputController
                                  control={control}
                                  defaultValue={8}
                                  label={t("description")}
                                  name="comment"
                              />
                              {errors.hours &&
                                  <span style={{ fontSize: 12, color: "red" }}>{errors.hours.message}</span>}
                          </FormControl>
                      </Grid>
                  </Grid>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose}>{t("cancel")}</Button>
                  <Button type="submit">{t("update")}</Button>
              </DialogActions>
          </form>
      </Dialog>
  )
}
