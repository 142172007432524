import { useState } from "react";
import {format, parseISO} from "date-fns";
import {IconButton, Box} from "@mui/material";
import {InsertInvitation, Edit, EventRepeat} from "@mui/icons-material";

import {CustomToolbarButtonComponent} from "../../../components/MuiTable";
import {MuiTableComponent, Translation, TranslationEN} from "../../../components/MuiTable";
import { DialogMonthlyHourComponent } from "./DialogMonthlyHourComponent";
import { Tooltip } from "@mui/material";
import { CustomAxios } from "../../../custom/axiosCustom";
import { SuccessAlert } from "../../../custom/SwalCustom";
import { Config } from "../../../utils/configHeader";
import Swal from "sweetalert2";
import { from } from "stylis";

export const MissingHourSummaryComponent = ({notLoading, thisLoading, openDialog, data, t, language, projectData, reloadProjectData, reloadInitialMissingHour}) => {
    const [showProjects, setShowProjects] = useState(false)
    const [openMonthlyDialog, setMonthlyDialog] = useState(false)
    const [issueId, setIssueId] = useState()
    const [monthlyHour, setMonthlyHour] = useState()

    const onClickOpenDialog = (index)=>{
        let missingHour = data[index]
        openDialog(missingHour)
    }

    const onEdit = (index) => {
        let monthlyHour = projectData[index]
        setMonthlyHour(monthlyHour)
        onOpenMonthlyDialog()
    }

    const onchargedWeekHour = async (index) => {
        let missingHour = data[index]
        const issueResponse = await CustomAxios.get(`projects/${missingHour.projectId}/issues`, Config());
        let issueId = issueResponse.data[0].id
        const template = `<p>${t("textChargeWeekHour", { hours: missingHour.missingHours, project: missingHour.projectName })}</p><br/>
                            <ul>${missingHour.days.map(item => `<li>${format(parseISO(item.date), 'dd/MM/yyyy')}</li>`).join("")}</ul>`

        Swal.fire({
            title: t("loadHours"),
            html: template,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: t("load"),
            cancelButtonText: t("cancel"),
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                let value = {}
                value["projectId"] = missingHour.projectId
                value["hourProductive"] =  missingHour.missingHours
                value["issueId"] = issueId
                value["type"] = "Automatic"
                value["typeOfCharge"] = "Week"
                value["hoursPerDay"] = 8
                value["localDate"] = new Date(missingHour.from).toISOString().substring(0, 10);
                CustomAxios.post("time-entry/charge-hours", value, Config()).then(() => {
                    notLoading()
                    SuccessAlert(t("loadHours"), t("doneSuccessfully", {a: "a"})).then(()=> reloadInitialMissingHour())
                }).catch(({ response: { data } }) => {
                    notLoading()
                    ErrorAlert(t("errorTitle"), data.message).then()
                })
            }
          });
    }

    const onOpenMonthlyDialog = () => setMonthlyDialog(true)
    const onCloseMonthlyDialog = () => setMonthlyDialog(false)

    

    const columns = [
        {
            name: "projectName",
            label: t("project"),
        },
        {
            name: "from",
            label: t("from"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value === undefined ? '' : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "to",
            label:t("to"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value === undefined ? '' : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "missingHours",
            label: t("missingHours"),
        },
        {
            name: t("registerHour"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            <IconButton onClick={() => { onClickOpenDialog(dataIndex) }}>
                                <InsertInvitation />
                            </IconButton>
                            <Tooltip title={t("weeklyHoursLoad")}>
                                <IconButton onClick={() => { onchargedWeekHour(dataIndex) }}>
                                    <EventRepeat sx={{ mt: '-1px' }} />
                                </IconButton>
                            </Tooltip>
                        </>

                    );
                }
            }
        },
    ]

    const projectColumns = [
        { name: "projectName", label: t("project") },
        { name: "projectId", label: "id", options: { display: false,filter: false } },
        { name: "activityId", label: "id", options: { display: false, filter: false } },
        { name: "id", label: "id", options: { display: false, filter: false } },
        {
            name: "spentOn",
            label: t("date"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value === undefined ? '' : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "hours",
            label: t("hours")
        },
        {
            name: "activityName",
            label: t("activities"),
        },
        {
            name: "comment",
            label: t("description"),
        },
        {
            name: "Acciones",
            label: t("actions"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Box display="flex" flexDirection="row">
                            <IconButton onClick={() => onEdit(dataIndex)}>
                                <Edit />
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
    ]

    const onClickAction = () => {
       setShowProjects(!showProjects)
    }

    const options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels: language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '400px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
        customToolbar: () => {
            return (
                <>
                    <CustomToolbarButtonComponent
                        title={showProjects ? t('missingHours') : t("timesList")}
                        onClickAction={onClickAction}
                        t={t}
                    />
                </>
            );
        }
    };

    return (
        <>
            {!showProjects && (
                <MuiTableComponent data={data} title={t("missingHours")} options={options} columns={columns} />
            )}
             {showProjects && (
                <MuiTableComponent data={projectData} title={t("timesList")} options={options} columns={projectColumns} />
            )}

            <DialogMonthlyHourComponent
                handleClose={onCloseMonthlyDialog}
                open={openMonthlyDialog}
                loading={thisLoading}
                notLoading={notLoading}
                data={monthlyHour}
                t={t}
                reloadProjectData={reloadProjectData}
            />   
        </>

    )
}
