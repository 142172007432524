import {TableFooter} from "@mui/material";
import {dollarUS} from "../../../../utils/moneyFormat";
import {TableCellTotal} from "../../../../components/MuiTable/tableCellTotal";
import {TableCellStandart} from "../../../../components/MuiTable/tableCellStandart";

export const TableFooterDetailsInvoiceComponent = ({values}) => {
    if (values === undefined) return <></>;
    const {columns, data, count} = values;
    if (data.length === 0) return <></>;
    let counter = []
    data.map((value, index) => {
        value.data.map((e, i) => {
            if (i > 1) {
                let values = e
                if (e !== undefined && e !== null) {
                    if (e.toString().startsWith("-")) {
                        let newValue = e.toString().replaceAll("$", "").replaceAll(",", "")
                        values = Number(newValue.toString())
                    }

                    if (e.toString().startsWith("$")) {
                        let newValue = e.toString().replaceAll("$", "").replaceAll(",", "")
                        values = Number(newValue.toString())
                    }
                }

                counter[i] = counter[i] === undefined ? values : parseFloat(counter[i] + values);
            }
        })
    })

    return (
        <>
            <TableFooter>
                {columns.map(({display, name}, index) => {
                    if (display === "true") {
                        if (name === "reference") {
                            return (
                                <TableCellTotal key={index} value={"Total"} index={index}/>
                            );
                        }
                        if (index > 1) {
                            if (name === "units" || name === "iva"){
                                return (
                                    <TableCellStandart key={index} value={counter[index]} index={index}/>
                                );
                            }
                            return (
                                <TableCellStandart key={index} value={dollarUS.format(counter[index])} index={index}/>
                            );
                        }
                    }
                })
                }
            </TableFooter>
        </>
    );
};
