import React, { useEffect, useState } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel, MenuItem, Select,
    TextField
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import format from "date-fns/format";

import { ErrorAlert, SuccessAlert } from "../../../custom/SwalCustom";
import SelectActivityComponent from "./selectActivityComponent";
import { InputController } from "../../../components/CustomInput";
import { CustomAxios } from "../../../custom/axiosCustom";
import { Config } from "../../../utils/configHeader";

export const DialogMissingHourComponent = ({
    handleClose, 
    open,
    notLoading,
    loading,
    missingHour,
    reloadInitialMissingHour,
    t
}) => {
    const schema = yup.object().shape({
        activityId: yup.string().typeError(t("isRequired")).required(t("isRequired")),
        spentOn: yup.date().typeError(t("isRequired")).required(t("isRequired")),
        comment: yup.string().typeError(t("isRequired")).required(t("isRequired")),
    }).required();

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const [activityList, setActivityList] = useState([]);
    const [issueId, setIssueId] = useState();

    useEffect(() => {
        loading();
        CustomAxios.get(`time-entry/activities`, Config()).then(({ data }) => {
            setActivityList(data);
            notLoading();
        });
    }, [open]);

    useEffect(() => {
        if (missingHour !== null) {
            loading();
            CustomAxios.get(`projects/${missingHour.projectId}/issues`, Config()).then(({ data }) => {
                setIssueId(data[0].id);
                notLoading();
            });
        }
    }, [missingHour]);

    const onRegister = (value) => {
        handleClose(); 
        loading(); 
    
        value["projectId"] = missingHour.projectId;
        let [element] = missingHour.days.filter(day => day.date === format(value["spentOn"], 'yyyy-MM-dd'));
    
        value["issueId"] = issueId;
    
        const selectedActivity = activityList.find(activity => activity.id === Number(value.activityId));
    
        value["hours"] = selectedActivity && (selectedActivity.description === 'Festive' || selectedActivity.description === 'Unpaid Leave') ? 0 : element.missingHours;
    
        CustomAxios.post(`time-entry`, value, Config())
            .then(() => {
                notLoading(); 
                SuccessAlert(t("loadHours"), t("doneSuccessfully", {a: "a"})).then(() => reloadInitialMissingHour());
            })
            .catch(({ response: { data } }) => {
                notLoading(); 
                ErrorAlert(t("errorTitle"), data.message).then();
            });
    };
    

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            {missingHour !== null && (
                <form onSubmit={handleSubmit(onRegister)}>
                    <DialogTitle>{t("loadRemainingHours")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        label={t("project")}
                                        margin="dense"
                                        value={missingHour.projectName}
                                        fullWidth
                                        name="project"
                                        id="formatted-numberformat-input"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    {errors.project &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.project.message}</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <SelectActivityComponent control={control} defaultValue={[]}
                                        label={t("activities")}
                                        name="activityId"
                                        listValue={activityList} />
                                    {errors.activityId &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.activityId.message}</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='spentOn'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">{t("date")}</InputLabel>
                                                <Select
                                                    {...field}
                                                >
                                                    {missingHour.days?.map(({ date, missingHours }, index) => (
                                                        <MenuItem value={date} key={index}>
                                                            {date} - {t("hoursRemaining")}: {missingHours}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                    {errors.spentOn &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.spentOn.message}</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputController name="comment" control={control}
                                        type="text"
                                        defaultValue="" label={t("description")} />
                                    {errors.comment &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.comment.message}</span>}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t("cancel")}</Button>
                        <Button type="submit">{t("load")}</Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};
