const Config = () => {
    const {token} =JSON.parse(localStorage.getItem("user"));
    const language = localStorage.getItem("language");
    if (token !== "") {
        if (token !== undefined) {
            return {
                headers: {
                    Authorization: `Bearer ${token.replaceAll("\"", "")}`,
                    "Accept-Language" : language
                }
            }
        }

    } else {
        return {
            headers: {}
        }
    }

}
const ConfigParams = (paramsBenefits) => {
    const {token} =JSON.parse(localStorage.getItem("user"));
    if (token !== "") {
        if (token !== undefined) {
           if (paramsBenefits != "" ) {
            return {
                headers: {
                    Authorization: `Bearer ${token.replaceAll("\"", "")}`,
                },
                params: {
                    benefits: paramsBenefits.join(',')
                }
            }
           } else {
            return {
                headers: {
                    Authorization: `Bearer ${token.replaceAll("\"", "")}`,
                },
            }
           }
        }

    } else {
        return {
            headers: {}
        }
    }

}

const ConfigHeaderMultiPart = () => {
    const {token} =JSON.parse(localStorage.getItem("user"));
    const language = localStorage.getItem("language");
    if (token !== "") {
        if (token !== undefined) {
            return {
                headers: {
                    Authorization: `Bearer ${token.replaceAll("\"", "")}`,
                    'Content-Type': 'multipart/form-data',
                    "Accept-Language" : language
                }
            }
        }
    } else {
        return {
            headers: {}
        }
    }
}

const ConfigHeaderBlobResponse = () => {
    const {token} =JSON.parse(localStorage.getItem("user"));
    const language = localStorage.getItem("language");
    if (token !== "") {
        if (token !== undefined) {
            return {
                headers: {
                    Authorization: `Bearer ${token.replaceAll("\"", "")}`,
                    "Accept-Language" : language
                },
                responseType: "blob"
            }
        }
    } else {
        return {
            headers: {}
        }
    }
}

const BeareToken = () => {
    const {token} =JSON.parse(localStorage.getItem("user"));
    if (token !== "") {
        if (token !== undefined) {
            return `Bearer ${token.replaceAll("\"", "")}`
        }
    } else {
        return ''
    }
}

export {Config,ConfigParams, ConfigHeaderMultiPart, ConfigHeaderBlobResponse, BeareToken}
