import {useEffect, useState} from "react";

import {Box, Button, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";

import MDBox from "../../../components/MDBox";
import {InputController} from "../../../components/CustomInput";
import Loading from "../../../examples/loading";
import {CustomAxios} from "../../../custom/axiosCustom";


export const FormHourComponent = () => {
    const {id, token, projectName, localDate, type} = useParams();
    const [totalHour, setTotalHour] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [errorInput, setErrorInput] = useState({
        hourProductive: false,
        hourVacation: false,
        hourDisease: false,
        hourOther: false
    })

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: {errors, isSubmitSuccessful}
    } = useForm({mode: "onChange"});
    const rulesDefault = {required: true, max: 40}

    const hourProductive = watch("hourProductive")
    const hourVacation = watch("hourVacation")
    const hourDisease = watch("hourDisease")
    const hourOther = watch("hourOther")

    useEffect(() => {
        const isValid = isErrorHour(hourVacation)
        setErrorInput({
                ...errorInput,
                hourVacation: isValid
            }
        )
    }, [hourVacation])

    useEffect(() => {
        const isValid = isErrorHour(hourProductive)
        setErrorInput({
                ...errorInput,
                hourProductive: isValid
            }
        )
    }, [hourProductive])

    useEffect(() => {
        const isValid = isErrorHour(hourDisease)
        setErrorInput({
                ...errorInput,
                hourDisease: isValid
            }
        )
    }, [hourDisease])

    useEffect(() => {
        const isValid = isErrorHour(hourOther)
        setErrorInput({
                ...errorInput,
                hourOther: isValid
            }
        )
    }, [hourOther])

    useEffect(() => {
        let total = 0
        if (hourDisease >= 0) {
            total = parseInt(total) + parseInt(hourDisease === "" ? 0 : hourDisease)
        }
        if (hourVacation >= 0) {
            total = parseInt(total) + parseInt(hourVacation === "" ? 0 : hourVacation)
        }
        if (hourProductive >= 0) {
            total = parseInt(total) + parseInt(hourProductive === "" ? 0 : hourProductive)
        }
        if (hourOther >= 0) {
            total = parseInt(total) + parseInt(hourOther === "" ? 0 : hourOther)
        }
        setTotalHour(total)
    }, [hourProductive, hourVacation, hourOther, hourDisease])

    const isValidForm = () => {
        if (errorInput.hourProductive) return false;
        if (errorInput.hourOther) return false;
        if (errorInput.hourDisease) return false;
        if (errorInput.hourVacation) return false;

        return true;

    }

    const onSubmit = (value) => {
        if (totalHour === 40) {
            if (isValidForm()) {
                setIsLoading(true)
                const request = {
                    id,
                    token,
                    projectName,
                    localDate,
                    type,
                    hourProductive: value.hourProductive,
                    hourVacation: value.hourVacation,
                    hourDisease: value.hourDisease,
                    hourOther: value.hourOther
                }
                CustomAxios.post(`time-entry/charge-hour`, request).then(() => {
                    setIsLoading(false)
                    Swal.fire("Registro Exitoso", "Se han cargado las horas con exito", "success").then(reset())
                }).catch(({response:{data}}) => {
                    setIsLoading(false)
                    Swal.fire("Error", data.message, "error").then()
                })
            }
        } else {
            Swal.fire("Cantidad de horas incorrectas", "El total de horas debe ser igual a 40", "info").then()
        }
    }

    const isErrorHour = (hour, name) => {
        if (hour > 0) {
            if (hour / 8 % 1 !== 0) {
                return true
            }
        }
        return false
    }
    return (
        <Box style={{paddingBottom: 20}}>
            <Loading open={isLoading}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent="center">
                            <Typography variant="h4">Carga de horas</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <MDBox ml={2} mr={2}>
                            <InputController control={control} name="hourProductive" defaultValue="0"
                                             label="Horas Productivas" rules={rulesDefault} type="number"/>
                            {errorInput.hourProductive && (
                                <span style={{fontSize: 12, color: "red"}}>Deben ser jonadas de 8 horas</span>)}
                            {errors.hourProductive && errors.hourProductive.type === "max" &&
                            <span style={{fontSize: 12, color: "red"}}>Valor maximo debe ser 40</span>}
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <MDBox mt={-1} ml={2} mr={2}>
                            <InputController control={control} name="hourVacation" defaultValue="0"
                                             label="Horas Vacaciones" rules={rulesDefault} type="number"/>
                            {errorInput.hourVacation && (
                                <span style={{fontSize: 12, color: "red"}}>Deben ser jonadas de 8 horas</span>)}
                            {errors.hourVacation && errors.hourVacation.type === "max" &&
                            <span style={{fontSize: 12, color: "red"}}>Valor maximo debe ser 40</span>}
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <MDBox mt={-1} ml={2} mr={2}>
                            <InputController control={control} name="hourDisease" defaultValue="0"
                                             label="Horas Enfermedad" rules={rulesDefault} type="number"/>
                            {errorInput.hourDisease && (
                                <span style={{fontSize: 12, color: "red"}}>Deben ser jonadas de 8 horas</span>)}
                            {errors.hourDesease && errors.hourDesease.type === "max" &&
                            <span style={{fontSize: 12, color: "red"}}>Valor maximo debe ser 40</span>}
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <MDBox mt={-1} ml={2} mr={2}>
                            <InputController control={control} name="hourOther" defaultValue="0"
                                             label="Otras Horas" rules={rulesDefault} type="number"/>
                            {errorInput.hourOther && (
                                <span style={{fontSize: 12, color: "red"}}>Deben ser jonadas de 8 horas</span>)}
                            {errors.hourOther && errors.hourOther.type === "max" &&
                            <span style={{fontSize: 12, color: "red"}}>Valor maximo debe ser 40</span>}
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="end" style={{marginLeft: 20, marginRight: 20}}>
                            <Typography variant="h6">Total de horas: {totalHour}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="end" style={{marginLeft: 20, marginRight: 20}}>
                            <Button style={{color: "white", backgroundColor: "black"}} type="submit"
                                    variant="contained" fullWidth>Cargar</Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}
