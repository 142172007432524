import {useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom'

const PrivateRoute = ({children}: { children: JSX.Element }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const location = useLocation();
    useEffect(() => {
        let storeUser = localStorage.getItem("user");
        if (storeUser && storeUser !== "") {
            let user = JSON.parse(storeUser);
            if (user.token != "") {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false)
            }
        }else{
            setIsAuthenticated(false);
        }
    }, [location])

    if (isAuthenticated === null) {
        return <></>
    }

    return (
        !isAuthenticated ? (
            <Navigate to='/'/>
        ) : (
            children
        )
    );
};

export default PrivateRoute;
