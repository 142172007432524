import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import { AccountCircle } from "@mui/icons-material";
import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import backgroundImage from "../../../../assets/images/profile.png";
import { useUserData } from "../../../../hooks/useUserData";
import DialogChangePassword from "../Dialog";
import { Config } from "../../../../utils/configHeader";
import { CustomAxios } from "../../../../custom/axiosCustom";
import { useLoading } from "../../../../hooks/useLoading";
import { MainLayout } from "../../../layout/MainLayout";
import { UpdateProfileComponent } from "../component/updateProfileComponent";

function Header({ children }) {
    const { t } = useTranslation();
    const { user, rolCustom } = useUserData()
    const [openDialog, setOpenDialog] = useState()
    const [update, setUpdate] = useState(false)
    const { loading, thisLoading, notLoading } = useLoading()
    const [userData, setUserData] = useState()

    useEffect(() => {
        thisLoading()
        CustomAxios.get(`user-profile`, Config()).then(({ data }) => {
            notLoading()
            setUserData(data)
        }).catch(() => {
        })
    }, [update]);

    const handleClose = () => {
        setOpenDialog(false);
    }

    const OnClickUpdate = () => setUpdate(!update)

    const onRegister = (value) => {
        if (value.password === value.repeatPassword) {
            handleClose()
            thisLoading()
            value["id"] = user.id
            CustomAxios.post(`user/change-password`, value, Config()).then(() => {
                notLoading()
                Swal.fire(
                    t("changePassword"),
                    t("doneSuccessfully", { a: "o" }),
                    'success'
                ).then()
            }).catch(() => {
                notLoading()
                Swal.fire(
                    t("errorTitle"),
                    t("internalError"),
                    'error'
                ).then()
            })
        }
    }

    return (
        <MainLayout loading={loading}>
            <MDBox position="relative" mb={5}>
                <MDBox
                    display="flex"
                    position="relative"
                    borderRadius="xl"
                    sx={{
                        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.info.main, 0.1),
                                rgba(gradients.info.state, 0.2)
                            )}, url(${backgroundImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        overflow: "hidden",
                        minHeight: "28rem"
                    }}
                />
                <Card
                    sx={{
                        position: "relative",
                        mt: -8,
                        mx: 3,
                        py: 2,
                        px: 2,
                    }}
                >
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={6} lg={8}>
                            <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
                                <Avatar sx={{ width: 56, height: 56, bgcolor: grey[900] }}>
                                    <AccountCircle sx={{ width: 36, height: 36 }} />
                                </Avatar>
                                <Box ml={1} height="100%" lineHeight={1}>
                                    <MDTypography variant="h5" fontWeight="medium">
                                        {userData !== undefined && userData?.firstName + " " + userData?.lastName}
                                    </MDTypography>
                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                        {rolCustom}
                                    </MDTypography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
                            <AppBar position="static">
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <Button
                                            onClick={OnClickUpdate}>{!update ? t("updateData") : t("seeData")}</Button>
                                    </Grid>
                                </Grid>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12}>
                            {!update && (
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{t("personalInformation")}: </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("name")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.firstName}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("lastname")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.lastName}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("gender")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.gender}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("birthdate")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.dateOfBirth}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("areaCode")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.areaCode}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("phone")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.phoneNumber}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("passport")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.passportNumber}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("postalCode")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.postalCode}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("address")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.address}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("country")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.country}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("nationality")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.nationality}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("email")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.email}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{t("emergencyContact")}: </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("nameEmergencyContact")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.nameEmergencyContact}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("relationshipEmergencyContact")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.relationshipEmergencyContact}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("codePhoneEmergency")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.areaCodeEmergencyContact}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("phoneEmergencyContact")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.phoneNumberEmergencyContact}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{t("professionalInformation")}: </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row">
                                                    <Typography variant="h6">{t("corporateEmail")}: </Typography>
                                                    <Typography variant="p"
                                                        sx={{ fontSize: 15 }}>{userData?.companyEmail}</Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Box display="flex" flexDirection="row"  alignContent="center"
                                                    alignItems="center">
                                                    <Typography variant="h6">{t("professionalProfile")}: </Typography>
                                                    <Grid item xs={12}>
                                                    {userData?.professionalProfile.map((profile, index) => (
                                                        <Chip key={index}
                                                            label={profile}
                                                            style={{
                                                                backgroundColor: "black",
                                                                color: "white",
                                                                margin: 2
                                                            }}
                                                        />
                                                    )
                                                    )}
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {update && (
                                <>
                                    <UpdateProfileComponent
                                        user={userData}
                                        notLoading={notLoading}
                                        thisLoading={thisLoading}
                                        setUpdate={setUpdate}
                                        t={t}
                                    />
                                </>
                            )}

                        </Grid>
                    </Grid>
                    {children}
                </Card>
                <DialogChangePassword
                    handleClose={handleClose}
                    openDialog={openDialog}
                    onRegister={onRegister}
                />
            </MDBox>
        </MainLayout>
    );
}

Header.defaultProps = {
    children: "",
};

Header.propTypes = {
    children: PropTypes.node,
};

export default Header;
