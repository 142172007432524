import React, {useEffect, useState} from "react";

import {useParams} from 'react-router-dom';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {Check, PriorityHigh} from "@mui/icons-material";

import MDBox from "../../../components/MDBox";
import BasicLayout from "../../authentication/components/BasicLayout";
import bgImage from "../../../assets/images/sign_in_image_background.jpg";
import Dominion from "../../../assets/images/logos/logo.svg"
import {setLayout, useMaterialUIController} from "../../../context";
import {CustomAxios} from "../../../custom/axiosCustom";
import MDButton from "../../../components/MDButton";
import {ConfigHeaderBlobResponse} from "../../../utils/configHeader";
import {useLoading} from "../../../hooks/useLoading";
import {SendAutomaticInvoiceOutLoginComponent} from "./sendAutomaticInvoiceOutLoginComponent";
import {SendManualOutLoginComponent} from "./sendManualOutLoginComponent";

const ConfirmationInvoiceOutLoginComponent = () => {
    localStorage.setItem("token", "")
    const {loading, thisLoading, notLoading} = useLoading()
    const {id, token} = useParams();
    const [error, setError] = useState(false)
    const [, dispatch] = useMaterialUIController();
    const [dialogAutomaticCharge, setDialogAutomaticCharge] = useState(false)
    const [dialogManualCharge, setDialogManualCharge] = useState(false)

    const onOpenDialogAutomaticCharge = () => setDialogAutomaticCharge(true)
    const onCloseDialogAutomaticCharge = () => setDialogAutomaticCharge(false)
    const onOpenDialogManualCharge = () => setDialogManualCharge(true)
    const onCloseDialogManualCharge = () => setDialogManualCharge(false)

    const onDownload = () => {
        thisLoading()
        CustomAxios.get(`invoice-confirmations/download/${token}/${id}`, ConfigHeaderBlobResponse())
            .then(({data, headers}) => {
                const link = document.createElement("a");
                link.target = "_blank";
                link.download = `factura${new Date().getTime()}.pdf`;
                link.href = window.URL.createObjectURL(
                    new Blob([data], {
                        type: headers["content-type"],
                    })
                );
                link.click();
                notLoading()
            }).catch(() => notLoading())
    }

    const ShowIcons = () => {
        if (!loading && !error) {
            return (
                <>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{mt: 5}}>
                        <Check color="success" fontSize='large'/>
                        <Typography>Tu factura fue cargada con exito</Typography>
                    </Box>
                    <Grid container spacing={3} sx={{mt: 5, p: 2}}>
                        <Grid item xs={12} md={6}>
                            <MDButton variant="gradient" type="submit" color="info" fullWidth
                                      onClick={onDownload}>Descargar</MDButton>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDButton variant="gradient" type="submit" color="info" fullWidth
                                      onClick={onOpenDialogManualCharge}>Carga Manual</MDButton>
                        </Grid>
                        <Grid item xs={12}>
                            <MDButton variant="gradient" type="submit" color="info" fullWidth
                                      onClick={onOpenDialogAutomaticCharge}>Carga
                                Automatica</MDButton>
                        </Grid>
                    </Grid>
                </>
            )
        }
        if (!loading && error) {
            return (
                <>
                    <PriorityHigh color="error" fontSize='large'/>
                    <Typography>Este link ya fue utilizado</Typography>
                </>
            )
        }

        if (loading) {
            return (
                <>
                    <CircularProgress/><Typography>Cargando...</Typography>
                </>
            )
        }
        return <></>
    }

    useEffect(() => {
        setLayout(dispatch, "pages")
    }, []);

    useEffect(() => {
        thisLoading()
        CustomAxios.post(`invoice-confirmations/confirm/${token}/${id}`).then(() => {
            notLoading()
        }).catch(() => {
            notLoading()
        })
    }, []);


    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    bgColor="black"
                    borderRadius="lg"
                    coloredShadow="grey"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <Grid container spacing={2} justifyContent="center" sx={{mt: 1, mb: 2}}>
                        <img alt="image" src={Dominion} height="90%" width="90%"/>
                    </Grid>
                </MDBox>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                     height="300px">
                    <ShowIcons/>
                </Box>
            </Card>
            <SendAutomaticInvoiceOutLoginComponent
                handleClose={onCloseDialogAutomaticCharge}
                open={dialogAutomaticCharge}
                notLoading={notLoading}
                loading={thisLoading}
                token={token}
                id={id}
            />
            <SendManualOutLoginComponent
                handleClose={onCloseDialogManualCharge}
                open={dialogManualCharge}
                notLoading={notLoading}
                loading={thisLoading}
                token={token}
                id={id}
            />
        </BasicLayout>
    )
}

export default ConfirmationInvoiceOutLoginComponent;
