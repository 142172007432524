import { Tooltip, IconButton } from "@mui/material";
import { Refresh } from "@mui/icons-material";

export const CustomToolbarReloadComponent = ({ title, onClickAction }) => {
    return (
        <>
            <Tooltip title={title}>
                <IconButton onClick={onClickAction} >
                    <Refresh />
                </IconButton>
            </Tooltip>
        </>
    );
};
