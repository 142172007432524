import React, { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Config } from "../../../../utils/configHeader";
import { dollarUS } from "../../../../utils/moneyFormat";
import { CustomAxios } from "../../../../custom/axiosCustom";
import { MainLayout } from "../../../layout/MainLayout";
import { useLoading } from "../../../../hooks/useLoading";
import MDBox from "../../../../components/MDBox";
import { DialogPostulateCandidateComponent } from "./DialogPostulateCandidateComponent";

export default function JobDetailComponent() {
    const { t } = useTranslation();
    const { loading, notLoading, thisLoading } = useLoading()
    const [openDialog, setOpenDialog] = useState(false);
    const [vacancy, setVacancy] = useState()
    const { id } = useParams()

    useEffect(() => {
        thisLoading()
        CustomAxios.get(`vacancies/${id}`, Config()).then(({ data }) => {
            setVacancy(data)
            notLoading()
        })
    }, [])

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
    return (
        <MainLayout loading={loading}>
            <MDBox py={3}>
                {vacancy !== undefined && (
                    <>
                        <Card>
                            <CardHeader title={vacancy.name} />
                            <CardContent>
                                <Typography variant="h5">
                                    {t("description")}:
                                </Typography>
                                <Typography paragraph style={{ fontSize: "15px" }}>
                                    {vacancy.description}
                                </Typography>
                                <Typography variant="h5">
                                    {t("responsibilities")}:
                                </Typography>
                                <Typography paragraph style={{ fontSize: "15px" }}>
                                    {vacancy.responsibilities}
                                </Typography>
                                <Typography variant="h5">
                                    {t("requiredTechnologies")}:
                                </Typography>
                                <Typography component={'div'} style={{ fontSize: "15px" }}>
                                    <ul style={{ marginLeft: 30 }}>
                                        {vacancy.techStack.map((techStack, index) => (
                                            <li key={index}>{techStack}</li>
                                        ))}
                                    </ul>
                                </Typography>
                                <Typography variant="h5">
                                    {t("requiredLanguage")}:
                                </Typography>
                                <Typography paragraph style={{ fontSize: "15px" }}>
                                    {vacancy.language}
                                </Typography>
                                <Typography variant="h5">
                                    {t("estimatedDurationTime")}:
                                </Typography>
                                <Typography paragraph style={{ fontSize: "15px" }}>
                                    {vacancy.estimatedHiringTime}
                                </Typography>
                                {/*<Typography variant="h5">*/}
                                {/*    Rate máximo:*/}
                                {/*</Typography>*/}
                                {/*<Typography paragraph style={{fontSize: "15px"}}>*/}
                                {/*    {dollarUS.format(vacancy.rateMaxUSD)}*/}
                                {/*</Typography>*/}
                                {/*<Typography variant="h5">*/}
                                {/*    Rate mínimo:*/}
                                {/*</Typography>*/}
                                {/*<Typography paragraph style={{fontSize: "15px"}}>*/}
                                {/*    {dollarUS.format(vacancy.rateMinUSD)}*/}
                                {/*</Typography>*/}
                            </CardContent>
                            <MDBox mb={2} mr={2} ml={2} mt={2}>
                                <Grid container spacing={2} direction="row" justifyContent="flex-end"
                                    alignItems="flex-end">
                                    <Grid item xs={12} md={6} lg={3}>
                                        <MDBox mb={2}>
                                            <Button
                                                onClick={handleClickOpen}
                                                style={{ backgroundColor: "#479925", color: "white" }}
                                                variant="contained"
                                                fullWidth>
                                                {t("nominateCandidate")}
                                            </Button>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Card>

                        <DialogPostulateCandidateComponent
                            handleClose={handleClose}
                            open={openDialog}
                            vacancyId={vacancy.id}
                            notLoading={notLoading}
                            loading={thisLoading}
                            t={t}
                        />

                    </>
                )}
            </MDBox>
        </MainLayout>
    );
}
