import Swal from "sweetalert2";
import i18next from "i18next";

import {CustomAxios} from "../../../custom/axiosCustom";
import {Config} from "../../../utils/configHeader";
import {ErrorAlert, SuccessAlert} from "../../../custom/SwalCustom";

export const sendInvoiceAdminFunction = async (thisLoading, notLoading, invoiceId, redmineId) => {
    const {value: email} = await Swal.fire({
        icon: 'info',
        title: i18next.t("billShipping"),
        input: 'email',
        inputLabel: i18next.t("copyEmail"),
        inputPlaceholder: i18next.t("enterCopyEmail"),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: i18next.t("sentInvoice"),
        cancelButtonText: i18next.t("cancel"),
    })

    if (email) {
        thisLoading()
        let request = {
            ccEmail: email,
            invoiceId: invoiceId
        }
        CustomAxios.post(`admin/invoice/send/${redmineId}`, request, Config()).then(() => {
            notLoading()
            SuccessAlert(i18next.t("billShipping"), i18next.t("doneSuccessfully", {a:"a"})).then()
        }).catch(({response: {data}}) => {
            notLoading()
            ErrorAlert(i18next.t("errorSendingInvoice"), data.message).then()
        })
    }
}
