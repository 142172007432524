import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

import {MainLayout} from "../../layout/MainLayout";
import {useLoading} from "../../../hooks/useLoading";
import {MuiTableComponent, Translation, TranslationEN} from "../../../components/MuiTable";
import {CustomAxios} from "../../../custom/axiosCustom";
import {Config} from "../../../utils/configHeader";
import {ErrorServer} from "../../../custom/SwalCustom";

export const CandidateSend = () => {
    const {t, i18n: {language}} = useTranslation();
    const {loading, thisLoading, notLoading} = useLoading()
    const [data, setData] = useState()

    useEffect(()=>{
        thisLoading()
        CustomAxios.get("vacancy-applications", Config()).then(({data}) => {
            setData(data)
            notLoading()
        }).catch(()=>{
            ErrorServer().then()
        })
    }, [])

    const columns = [
        {
            name: "firstName",
            label: t("name"),
        },
        {
            name: "lastName",
            label: t("lastname"),
        },
        {
            name: "vacancyName",
            label: t("vacancy"),
        },
        {
            name: "email",
            label: t("emailConsultant"),
        },
        {
            name: "status",
            label: t("status"),
        },
    ];

    const options = {
        search: true,
        download: true,
        print: false,
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels: language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '600px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
    };

    return (
        <MainLayout loading={loading}>
            <MuiTableComponent columns={columns} data={data} title={t("submittedCandidates")} options={options}/>
        </MainLayout>

    )
}
