import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { CustomAxios } from "../../../custom/axiosCustom";
import { Config } from "../../../utils/configHeader";
import { ErrorAlert, SuccessAlert, ErrorServer } from "../../../custom/SwalCustom";
import SelectProjectRegisterHourComponent from "./selectProjectRegisterHourComponent";
import { InputController } from "../../../components/CustomInput";
import SelectIssuesComponent from "./SelectIssuesComponent";

export default function DialogChargeHourJiraComponent({ handleClose, open, notLoading, loading, reloadInitialMissingHour, t }) {
    const schema = () => {
        let minHours = 1,
            hoursMessage = t("minimumValueShouldBeHour", {minHours: minHours})
        ;
        return yup.object().shape({
            projectId: yup.string().typeError(t("isRequired")).required(t("isRequired")),
            issueId: yup.string().typeError(t("isRequired")).required(t("isRequired")),
            hoursPerDay: yup.number().typeError(t("isRequired")).required(t("isRequired")).min(minHours, hoursMessage),
        }).required();
    }

    const [listProject, setListProject] = useState([]);
    const [issues, setIssues] = useState();
    const [staffing, setStaffing] = useState();
    const [projects, setProjects] = useState();

    const { control, handleSubmit, reset, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema()),
    });

    const projectId = watch("projectId");

    useEffect(() => {
        if (open) {
            CustomAxios.get("projects/with-assignments/jira", Config()).then(({ data }) => {
                setListProject(data);
                setStaffing(data.filter(item => item.isStaffing));
                setProjects(data.filter(item => !item.isStaffing));
            }).catch(() => {
                ErrorServer().then()
            })
        }
    }, [open]);

    useEffect(() => {
        if (projectId > 0) {
            loading()
            CustomAxios.get(`projects/${projectId}/issues/jira`, Config()).then(({ data }) => {
                setIssues(data)
                notLoading()
            })
        }

    }, [projectId])

    const onClose = () => {
        handleClose()
        reset({
            projectId: "",
            issueId: "",
            hours: 0,
        })
    }

    const onRegister = (value) => {
        onClose()
        loading()
        CustomAxios.post("time-entry/charge-hours/jira", value, Config()).then(() => {
            notLoading()
            SuccessAlert(t("loadHoursInJira"), t("doneSuccessfully", { a: "a" })).then(() => reloadInitialMissingHour())
        }).catch(({ response: { data } }) => {
            notLoading()
            ErrorAlert(t("errorTitle"), data.message).then()
        }).finally(() => onClose())
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit(onRegister)}>
                <DialogTitle>{t("loadHoursInJira")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <SelectProjectRegisterHourComponent control={control} defaultValue={""} label={t("project")}
                                    name="projectId"
                                    listValue={listProject} listStaffing={staffing} listProjects={projects} />
                                {errors.projectId &&
                                    <span style={{ fontSize: 12, color: "red" }}>{errors.projectId.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <SelectIssuesComponent
                                    control={control}
                                    defaultValue={""}
                                    label={t("issue")}
                                    name="issueId"
                                    listValue={issues}
                                />
                                {errors.issueId &&
                                    <span style={{ fontSize: 12, color: "red" }}>{errors.issueId.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} id="hours-field">
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputController
                                    control={control}
                                    defaultValue={0}
                                    label={t("hours")}
                                    name="hoursPerDay"
                                />
                                {errors.hoursPerDay &&
                                    <span style={{ fontSize: 12, color: "red" }}>{errors.hoursPerDay.message}</span>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t("cancel")}</Button>
                    <Button type="submit">{t("load")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
