const symbolCurrency = () => {
    let setting = localStorage.getItem("setting")
    if (setting === null) {
        return "€"
    }
    let settingJson = JSON.parse(setting);
    return settingJson.currency.symbol
}

const prefixCurrency = () => {
    let setting = localStorage.getItem("setting")
    if (setting === null) {
        return "EUR"
    }
    let settingJson = JSON.parse(setting);
    return settingJson.currency.prefix
}

const symbolCurrencySystem = () => {
    let setting = localStorage.getItem("setting")
    if (setting === null) {
        return "€"
    }
    let settingJson = JSON.parse(setting);
    return settingJson.currencySystem.symbol
}

const prefixCurrencySystem = () => {
    let setting = localStorage.getItem("setting")
    if (setting === null) {
        return "EUR"
    }
    let settingJson = JSON.parse(setting);
    return settingJson.currencySystem.prefix
}

const setting = () => {
    let setting = localStorage.getItem("setting")
    return JSON.parse(setting);
}

const getUrlFile = (fileId, extension) => {
    return  "/v1/cms/file/"+fileId + "/" + extension
}

export {
    symbolCurrency,
    prefixCurrency,
    symbolCurrencySystem,
    prefixCurrencySystem,
    getUrlFile
}
