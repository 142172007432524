import { format } from 'date-fns';

/**
 * Formatea una fecha al formato 'dd/MM/yyyy'.
 * @param {string | Date} date - La fecha a formatear (puede ser una cadena o un objeto Date).
 * @param {string} fallback - Texto que se muestra si la fecha no es válida.
 * @returns {string} - La fecha formateada o el texto de fallback.
 */
export const formatDate = (date, fallback = 'Invalid Date') => {
  try {
    return format(new Date(date), 'dd/MM/yyyy');
  } catch (error) {
    return fallback;
  }
};

const getFormatDate = (value) => {

  if (!value) value = new Date();
  if (typeof value === "string") value = new Date(value);
  const currentDate = value;

  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear().toString();
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export { getFormatDate }