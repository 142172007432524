import {Grid} from "@mui/material";
import {InsertDriveFile} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

import MDBox from "../../../components/MDBox";
import MenuCard from "../../../examples/Cards/MenuCards";
import { usePermission } from "../../../hooks/usePermission";
import { userContainPermission } from "../../../utils/permission";

export const ItemInvoiceComponent = () => {
    const { t } = useTranslation();
    const { permissionList } = usePermission("Tipo Factura");

    const existPermission = (permission) => {
        return userContainPermission(permission, permissionList);
    };

    return (
        <Grid container spacing={3}>
            {existPermission("Factura Digital") && (
                <Grid item xs={12} md={6} lg={4} xl={4}>
                    <MDBox mb={1.5}>
                        <Link to="/gestion-de-facturas/prefactura">
                            <MenuCard
                                icon={InsertDriveFile}
                                title={t("preInvoice")}
                            />
                        </Link>
                    </MDBox>
                </Grid>
            )}
            <Grid item xs={12} md={6} lg={4} xl={4}>
                <MDBox mb={1.5}>
                    <Link to="/gestion-de-facturas/facturas">
                        <MenuCard
                            icon={InsertDriveFile}
                            title={t("invoices")}
                        />
                    </Link>
                </MDBox>
            </Grid>
        </Grid>
    )
}
