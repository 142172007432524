import { Tooltip, Button } from "@mui/material";
import { Add } from "@mui/icons-material";

export const CustomToolbarButtonComponent = ({ title, onClickAction, Icon, textButton, t }) => {
    return (
        <>
            <Tooltip title={title}>
                <Button variant="outlined" onClick={onClickAction} >
                    {title}
                </Button>
            </Tooltip>
        </>
    );
};