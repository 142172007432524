import React, { useEffect, useState } from 'react';
import {
    Chip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Visibility, Edit, Info } from '@mui/icons-material';

import { MainLayout } from '../layout/MainLayout';
import { useLoading } from '../../hooks/useLoading';
import { MuiTableComponent, Translation, TranslationEN } from '../../components/MuiTable';
import { CustomAxios } from '../../custom/axiosCustom';
import { Config } from '../../utils/configHeader';
import { ErrorServer } from '../../custom/SwalCustom';
import { CustomToolbarComponent } from './component/CustomToolbarComponent';
import { CustomToolbarReloadComponent } from '../../components/MuiTable/CustomToolbarReloadComponent';
import ReservationFlow from '../../components/ReservationFlow/ReservationFlow';
import Swal from 'sweetalert2';
import { transformReservationData } from "../../utils/transformReservationData";
import ReservationDetailModal from './component/ReservationDetailModal';
import { formatDate } from '../../utils/getFormatDate';

export const ManagementReservationComponent = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { loading, thisLoading, notLoading } = useLoading();
    const [data, setData] = useState([]);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);

    const [openReservationModal, setOpenReservationModal] = useState(false);
    const [reservationMode, setReservationMode] = useState('create');
    const [reservationInitialData, setReservationInitialData] = useState(null);

    const [openInfoModal, setOpenInfoModal] = useState(false);

    const isEditable = (status) => {
        const nonEditableStatuses = [
            'Aprobado Gerente',
            'Aprobado CEO',
            'Rechazado CEO',
            'Aprobado Gerente',
            'Rechazado Gerente',
        ];
        return !nonEditableStatuses.includes(status);
    };

    const handleCloseReservationModal = () => {
        if (reservationMode === 'create') {
            setReservationInitialData(null);
        }
        setOpenReservationModal(false);
    };

    const reloadInitial = () => {
        thisLoading();
        CustomAxios.get('reservations', Config())
            .then(({ data }) => {
                const transformedData = data.map(transformReservationData);
                setData(transformedData);
                notLoading();
            })
            .catch(() => {
                ErrorServer().then();
                notLoading();
            });
    };

    useEffect(() => {
        reloadInitial();
    }, []);

    const onClickAction = () => {
        setReservationMode('create');
        setReservationInitialData(null);
        setOpenReservationModal(true);
    };

    const statusChip = (value, type) => {
        if (!value) {
            return <Chip label={t('unknown')} color="default" style={{ margin: '0 auto' }} />;
        }

        const statusMapping = {
            'Pendiente Revisión CEO': { label: t('pendingReviewCEO'), color: 'warning' },
            'Aprobado CEO': { label: t('approvedCEO'), color: 'success' },
            'Rechazado CEO': { label: t('rejectedCEO'), color: 'error' },
            'Pendiente Revisión Gerente': { label: t('pendingReviewManager'), color: 'warning' },
            'Aprobado Gerente': { label: t('approvedManager'), color: 'success' },
            'Rechazado Gerente': { label: t('rejectedManager'), color: 'error' },
        };
        const status = statusMapping[value] || { label: t('unknown'), color: 'default' };
        return <Chip label={status.label} color={status.color} style={{ margin: '0 auto' }} />;
    };

    const handleViewDetails = (reservationId) => {
        const reservation = data.find((res) => res.id === reservationId);
        setSelectedReservation(reservation);
        setOpenDetailModal(true);
    };

    const handleCloseDetailModal = () => {
        setOpenDetailModal(false);
        setSelectedReservation(null);
    };

    const handleEditReservation = (reservationId) => {
        const reservation = data.find((res) => res.id === reservationId);
        const currentStatus = reservation?.status;

        if (!isEditable(currentStatus)) {
            let errorMessage = '';

            if (currentStatus === 'Aprobado Gerente' || currentStatus === 'Aprobado CEO') {
                errorMessage = t('errorCannotEditApprovedReservationMessage');
            } else if (currentStatus === 'Rechazado CEO') {
                errorMessage = t('errorCannotEditRejectedReservationMessage');
            }

            Swal.fire({
                icon: 'error',
                title: t('errorCannotEditReservationTitle'),
                text: errorMessage,
            });
            return;
        }

        console.log("Datos de reserva a editar:", reservation);
        setReservationMode('edit');
        setReservationInitialData(reservation);
        setOpenReservationModal(true);
    };

    const handleReservationCompleted = () => {
        reloadInitial();
    };

    const columns = [
        {
            name:'createdAt',
            label: t('createdAt'),
            options: {
                customBodyRender: (value) => {
                    return formatDate(value, t('invalidDate'));
                },
            }
        },
        {
            name: 'property',
            label: t('property'),
        },
        {
            name: 'startDate',
            label: t('startDate'),
            options: {
                customBodyRender: (value) => {
                    return formatDate(value, t('invalidDate'));
                },
            }
        },
        {
            name: 'endDate',
            label: t('endDate'),
            options: {
                customBodyRender: (value) => {
                    return formatDate(value, t('invalidDate'));
                },
            }
        },
        {
            name: 'totalPersonas',
            label: t('guestCount'),
            options: {
                customBodyRender: (value) => {
                    return (
                        <>
                            {value === 0 ? t('noPeople') : value === 1 ? `${value} ${t('person')}` : `${value} ${t('people')}`}
                        </>
                    )
                },
            }
        },
        {
            name: 'totalHabitacionesDisponibles',
            label: t('roomCount'),
            options: {
                customBodyRender: (value) => {
                    return (
                        <>
                            {value === 0 ? t('noRooms') : value === 1 ? `${value} ${t('room')}` : `${value} ${t('rooms')}`}
                        </>
                    )
                },
            }
        },
        {
            name: 'informacionAprobacionGerente',
            label: t('managerApproval'),
            options: {
                customBodyRender: (value) => {
                    return (
                        <>
                            {statusChip(value)}
                        </>
                    );
                },
            },
        },
        {
            name: 'status',
            label: t('status'),
            options: {
                customBodyRender: (value) => {
                    return statusChip(value);
                },
            },
        },
        {
            name: 'actions',
            label: t('actions'),
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const reservation = data[rowIndex];
                    const reservationId = reservation?.id;
                    const currentStatus = reservation?.status;
                    const currentManagerApproval = reservation?.informacionAprobacionGerente;
                    const editable = isEditable(
                        currentStatus === 'Pendiente Revisión Gerente' ? currentManagerApproval : currentStatus
                    );

                    return (
                        <>
                            <IconButton
                                color="primary"
                                onClick={() => handleViewDetails(reservationId)}
                                title={t('viewDetails')}
                            >
                                <Visibility />
                            </IconButton>
                            <IconButton
                                color="secondary"
                                onClick={() => handleEditReservation(reservationId)}
                                title={t('editReservation')}
                                disabled={!editable}
                            >
                                {editable ? <Edit /> : <Tooltip title={t('cannotEditReservation')}><Edit /></Tooltip>}
                            </IconButton>
                        </>
                    );
                },
            },
        }

    ];

    const options = {
        search: true,
        download: true,
        print: false,
        viewColumns: true,
        filter: true,
        filterType: 'dropdown',
        responsive: 'vertical',
        textLabels: language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '600px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
        customToolbar: () => {
            return (
                <>
                    <CustomToolbarReloadComponent
                        title={t('reloadData')}
                        onClickAction={reloadInitial}
                    />
                    <Tooltip title={t('reservationInfo')}>
                        <IconButton
                            onClick={() => {
                                Swal.fire({
                                    title: t('reservationStatusInfoTitle'),
                                    html: `
                                            <div style="text-align: left; font-family: 'Roboto', sans-serif; font-size: 16px; color: #333; line-height: 1.6;">
                                                <p style="margin-bottom: 15px;"><strong>${t('reservationStatusExplanation')}</strong></p>
                                                <p style="margin-bottom: 10px;"><strong>${t('pendingReviewManager')}:</strong> ${t('pendingReviewManagerExplanation')}</p>
                                                <p style="margin-bottom: 10px;"><strong>${t('approvedManager')}:</strong> ${t('approvedManagerExplanation')}</p>
                                                <p style="margin-bottom: 10px;"><strong>${t('rejectedManager')}:</strong> ${t('rejectedManagerExplanation')}</p>
                                                <p style="margin-bottom: 10px;"><strong>${t('pendingReviewCEO')}:</strong> ${t('pendingReviewCEOExplanation')}</p>
                                                <p style="margin-bottom: 10px;"><strong>${t('approvedCEO')}:</strong> ${t('approvedCEOExplanation')}</p>
                                                <p style="margin-bottom: 10px;"><strong>${t('rejectedCEO')}:</strong> ${t('rejectedCEOExplanation')}</p>
                                            </div>
                                        `,
                                    icon: 'info',
                                    confirmButtonText: t('close'),
                                    background: '#f9f9f9',
                                    customClass: {
                                        popup: '',
                                    },
                                    titleStyle: {
                                        color: '#1976d2',
                                        fontFamily: "'Roboto', sans-serif",
                                        fontSize: '28px',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        marginBottom: '20px',
                                    },
                                    htmlContainerStyle: {
                                        fontFamily: "'Roboto', sans-serif",
                                        fontSize: '16px',
                                        color: '#333',
                                        textAlign: 'left',
                                        lineHeight: '1.6',
                                        maxWidth: '600px',
                                    },
                                    confirmButtonColor: '#1976d2',
                                    confirmButtonStyle: {
                                        borderRadius: '8px',
                                        padding: '12px 24px',
                                        fontSize: '16px',
                                        fontFamily: "'Roboto', sans-serif",
                                        fontWeight: 'bold',
                                    },
                                    backdrop: `rgba(0, 0, 0, 0.4)`,
                                });
                            }}
                        >
                            <Info />
                        </IconButton>
                    </Tooltip>

                    <CustomToolbarComponent
                        title={t('registerReservation')}
                        onClickAction={onClickAction}
                        t={t}
                    />
                </>
            );
        },
    };

    return (
        <MainLayout loading={loading}>
            <MuiTableComponent
                columns={columns}
                data={data}
                title={t('reservations')}
                options={options}
            />
            <ReservationDetailModal
                open={openDetailModal}
                onClose={handleCloseDetailModal}
                reservation={selectedReservation}
                t={t}
                statusChip={statusChip}
            />

            <Dialog
                open={openReservationModal}
                onClose={handleCloseReservationModal}
                fullWidth
                maxWidth="md"
            >
                <ReservationFlow
                    handleCloseModal={handleCloseReservationModal}
                    mode={reservationMode}
                    initialData={reservationInitialData}
                    onReservationCompleted={handleReservationCompleted}
                />
            </Dialog>

            <Dialog
                open={openInfoModal}
                onClose={() => setOpenInfoModal(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>{t('reservationInfo')}</DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        {t('reservationInfoMessage')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenInfoModal(false)}>{t('close')}</Button>
                </DialogActions>
            </Dialog>
        </MainLayout>
    );
};