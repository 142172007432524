import React, {useEffect, useState} from "react";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";
import {Settings} from "@mui/icons-material";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {useIdleTimer} from 'react-idle-timer'
import {es} from "date-fns/locale";
import Swal from "sweetalert2";
import axios from "axios";

import MDBox from "./components/MDBox";
import Sidenav from "./examples/Sidenav";
import theme from "./assets/theme";
import {useMaterialUIController, setMiniSidenav, setOpenConfigurator} from "./context/index";
import themeDark from "./assets/theme-dark";
import routes from "./routes";
import routesSidenav from "./routesSidenav";
import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logos/logo.svg";
import url from "./config";
import {Config} from "./utils/configHeader";
import './i18n.js'

export default function App() {

    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();
    const timeout = 600000
    const [isIdle, setIsIdle] = useState(false)
    const handleOnActive = () => setIsIdle(false)
    const handleOnIdle = () => setIsIdle(true)
    const navigate = useNavigate();
    const {resume} = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
    })

    let auth = localStorage.getItem("token")

    useEffect(() => {
            if (auth !== "") {
                if (isIdle) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");

                    Swal.fire({
                        title: 'Inactividad',
                        text: "Se ha comprobado inactividad por más de 10 minutos en la aplicación, por su seguridad y la nuestra la sesión será cerrada ",
                        icon: 'info',
                    }).then((res) => {
                        axios.get(`${url}auth/logout`).then((response) => {
                            navigate("/")
                        })
                    })
                }
            }
        }, [isIdle]
    )

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }
            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    const configsButton = (
        <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{cursor: "pointer"}}
            onClick={handleConfiguratorOpen}
        >
            <Settings fontSize="small" color="inherit"/>
        </MDBox>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {layout === "inicio" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandWhite : brandDark}
                            brandName="Material Dashboard 2"
                            routes={routesSidenav}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                    </>
                )}
                <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to="/inicio"/>}/>
                </Routes>
            </ThemeProvider>
        </LocalizationProvider>
    );
}
