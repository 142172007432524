import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { InsertDriveFile } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import MDBox from "../../components/MDBox";
import MenuCard from "../../examples/Cards/MenuCards";
import { MainLayout } from "../layout/MainLayout";
import { DialogRegisterHourAutomaticComponent } from "./component/dialogRegisterHourAutomaticComponent";
import { useLoading } from "../../hooks/useLoading";
import { RegisterHourDescriptiveComponent } from "./component/RegisterHourDescriptiveComponent";
import { MissingHourSummaryComponent } from "./component/MissingHourSummaryComponent";
import { DialogChargedHourComponent } from "./component/dialogChargedHourComponent";
import { CustomAxios } from "../../custom/axiosCustom";
import { Config } from "../../utils/configHeader";
import { ErrorServer, ErrorAlert } from "../../custom/SwalCustom";
import { DialogMissingHourComponent } from "./component/dialogMissingHourComponent";
import { usePermission } from "../../hooks/usePermission";
import { userContainPermission } from "../../utils/permission";
import DialogChargeHourJiraComponent from "./component/DialogChargeHourJiraComponent";

export const ManagementHourView = () => {
    const { t, i18n: { language } } = useTranslation();
    const { loading, thisLoading, notLoading } = useLoading()
    const [missingHour, setMissingHour] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [openDialogHourDescriptive, setOpenDialogHourDescriptive] = useState(false)
    const [openDialogChargedHour, setOpenDialogChargedHour] = useState(false)
    const [openDialogChargedHourInJira, setOpenDialogChargedHourInJira] = useState(false)
    const [openDialogMissingHour, setOpenDialogMissingHour] = useState(false)
    const [data, setData] = useState([])
    const [projectData, setProjectData] = useState([])
    const { permissionList } = usePermission("Perfil de Imputacion");

    const existPermission = (permission) => {
        return userContainPermission(permission, permissionList);
    };

    const reloadInitial = () => {
        thisLoading()
        CustomAxios.get("time-entry/missing-hours-summary", Config()).then(({ data }) => {
            setData(data)
            notLoading()
        }).catch(() => {
            notLoading()
        })
    }

    const reloadProjectData = () => {
        thisLoading()
        CustomAxios.get("time-entry/monthly-summary-hours", Config()).then(({ data }) => {
            setProjectData(data)
            notLoading()
        }).catch(() => {
            notLoading()
        })
    }

    useEffect(() => {
        reloadInitial()
        reloadProjectData()
    }, []);

    useEffect(() => {
        thisLoading()
        CustomAxios.get("projects/with-assignments", Config()).then(({ data }) => {
            localStorage.setItem("project-assignments", JSON.stringify(data))
            notLoading()
        }).catch(({ response: { data } }) => {
            notLoading()
            ErrorAlert(t("Error"), data.message).then()
        })
    }, []);

    const onOpenDialog = () => setOpenDialog(true)
    const onCloseDialog = () => setOpenDialog(false)

    const onOpenDialogHourDescriptive = () => setOpenDialogHourDescriptive(true)
    const onCloseDialogHourDescriptive = () => setOpenDialogHourDescriptive(false)

    const onOpenDialogChargedHour = () => setOpenDialogChargedHour(true)
    const onCloseDialogChargedHour = () => setOpenDialogChargedHour(false)

    const onOpenDialogChargedHourInJira = () => setOpenDialogChargedHourInJira(true)
    const onCloseDialogChargedHourInJira = () => setOpenDialogChargedHourInJira(false)

    const onOpenDialogMissingHour = (missingHourExtern) => {
        setMissingHour(missingHourExtern)
        setOpenDialogMissingHour(true)
    }
    const onCloseDialogMissingHour = () => {
        setOpenDialogMissingHour(false)
    }

    return (
        <MainLayout loading={loading}>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    {existPermission("Imputacion con Comentarios") && (
                        <Grid item xs={12} md={6} lg={4} xl={4} onClick={onOpenDialogHourDescriptive}>
                            <MDBox mb={1.5}>
                                <MenuCard
                                    icon={InsertDriveFile}
                                    title={t("descriptiveHourLoad")}
                                />
                            </MDBox>
                        </Grid>
                    )}
                    {existPermission("Imputacion Automatica") && (
                        <Grid item xs={12} md={6} lg={4} xl={4} onClick={onOpenDialog}>
                            <MDBox mb={1.5}>
                                <MenuCard
                                    icon={InsertDriveFile}
                                    title={t("automaticHoursLoading")}
                                />
                            </MDBox>
                        </Grid>
                    )}
                    {existPermission("Imputacion General") && (
                        <Grid item xs={12} md={6} lg={4} xl={4} onClick={onOpenDialogChargedHour}>
                            <MDBox mb={1.5}>
                                <MenuCard
                                    icon={InsertDriveFile}
                                    title={t("loadHours")}
                                />
                            </MDBox>
                        </Grid>
                    )}
                    {existPermission("Imputacion con Jira") && (
                        <Grid item xs={12} md={6} lg={4} xl={4} onClick={onOpenDialogChargedHourInJira}>
                            <MDBox mb={1.5}>
                                <MenuCard
                                    icon={InsertDriveFile}
                                    title={t("loadHoursInJira")}
                                />
                            </MDBox>
                        </Grid>
                    )}
                </Grid>
                <MissingHourSummaryComponent
                    thisLoading={thisLoading}
                    notLoading={notLoading}
                    openDialog={onOpenDialogMissingHour}
                    data={data}
                    t={t}
                    language={language}
                    projectData={projectData}
                    reloadProjectData={reloadProjectData}
                    reloadInitialMissingHour={reloadInitial}
                />

                <DialogRegisterHourAutomaticComponent
                    handleClose={onCloseDialog}
                    open={openDialog}
                    loading={thisLoading}
                    notLoading={notLoading}
                    reloadInitialMissingHour={reloadInitial}
                    t={t}
                />

                <RegisterHourDescriptiveComponent
                    handleClose={onCloseDialogHourDescriptive}
                    open={openDialogHourDescriptive}
                    loading={thisLoading}
                    notLoading={notLoading}
                    reloadInitialMissingHour={reloadInitial}
                    t={t}
                />

                <DialogChargedHourComponent
                    handleClose={onCloseDialogChargedHour}
                    open={openDialogChargedHour}
                    loading={thisLoading}
                    notLoading={notLoading}
                    reloadInitialMissingHour={reloadInitial}
                    t={t}
                />
                <DialogMissingHourComponent
                    handleClose={onCloseDialogMissingHour}
                    open={openDialogMissingHour}
                    loading={thisLoading}
                    notLoading={notLoading}
                    missingHour={missingHour}
                    reloadInitialMissingHour={reloadInitial}
                    t={t}
                />
                <DialogChargeHourJiraComponent
                    handleClose={onCloseDialogChargedHourInJira}
                    open={openDialogChargedHourInJira}
                    loading={thisLoading}
                    notLoading={notLoading}
                    reloadInitialMissingHour={reloadInitial}
                    t={t}
                />

            </MDBox>
        </MainLayout>
    )
}
