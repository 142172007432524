import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import i18next from "i18next";

import { CustomAxios } from "../../../custom/axiosCustom";
import {
  Config,
  ConfigHeaderBlobResponse,
} from "../../../utils/configHeader";
import {
  ErrorAlert,
  InfoAlert,
  SuccessAlert,
} from "../../../custom/SwalCustom";
import { AddDetailComponent } from "../../management-invoice/component/pre-invoice/AddDetailComponent";
import { generateInvoiceFunction } from "../../management-invoice/component/pre-invoice/GenerateInvoiceFunction";

import { getFormatDate } from "../../../utils/getFormatDate";

export const PreInvoiceAdminComponent = ({ thisLoading, notLoading, userData }) => {
  const [addItemInvoiceDialog, setAddItemInvoiceDialog] = useState(false);
  const onActiveAddItemInvoiceDialog = () => setAddItemInvoiceDialog(true);
  const onDisabledAddItemInvoiceDialog = () => setAddItemInvoiceDialog(false);
  const urlAddItem = `admin/pre-invoice/add-item/${userData.id}`
  const urlPreInvoicePending = `admin/pre-invoice/pending/${userData.id}`
  const urlConfirmPreInvoice = `admin/pre-invoice/confirm/${userData.id}/`
  const urlSendInvoice = `admin/invoice/send/${userData.id}`

  const generatePreInvoice = () => {
    CustomAxios.get(`admin/pre-invoice/create/${userData.id}`, Config())
      .then(({ data }) => {
        generateInvoiceFunction(
          data,
          thisLoading,
          notLoading,
          onActiveAddItemInvoiceDialog,
          urlPreInvoicePending,
          urlConfirmPreInvoice,
          urlSendInvoice,
        );
      })
      .catch(({ response: { data } }) => {
        notLoading();
        if (data.code === 400) {
          ErrorAlert(i18next.t("errorToGeneratePreInvoiceTitle"), data.message).then();
          return;
        }
        ErrorAlert("Error", i18next.t("errorToGeneratePreInvoice")).then();
      });
  }

  const generatePreInvoiceLastMonth = () => {
    thisLoading();
    CustomAxios.get(`admin/pre-invoice/create-last-month/${userData.id}`, Config())
      .then(({ data }) => {
        notLoading();
        generateInvoiceFunction(
          data,
          thisLoading,
          notLoading,
          onActiveAddItemInvoiceDialog,
          urlPreInvoicePending,
          urlConfirmPreInvoice,
          urlSendInvoice
        );
      }).catch(({ response: { data } }) => {
        notLoading();
        if (data.code === 400) {
          ErrorAlert(i18next.t("errorToGeneratePreInvoiceTitle"), data.message).then();
          return;
        }
        ErrorAlert("Error",  i18next.t("errorToGeneratePreInvoice")).then();
      });
  }

  const validateHour = () => {
    CustomAxios.get(`admin/pre-invoice/validate-hours/${userData.id}`, Config())
      .then(async ({ data }) => {
        if (data.statusCode === 200) {
          await SuccessAlert(data.message).then();
        }
        if (data.statusCode === 100) {
          await InfoAlert(data.message).then();
        }
        if (data.statusCode === 400) {
          await ErrorAlert(data.message).then();
        }
        thisLoading();
        generatePreInvoice()
      }).catch((error) => {
        ErrorAlert("Error", i18next.t("errorToValidateLoadHour")).then();
      });
  }

  useEffect(() => {
    generatePreInvoiceInitial()
  }, [])

  const generatePreInvoiceInitial = () => {
    thisLoading();
    CustomAxios.get(`admin/pre-invoice/validate-last-month/${userData.id}`, Config())
      .then(async ({ data }) => {
        notLoading();
        if (data.statusCode === 200) {
          Swal.fire({
            html: `${data.message} <br><br> ${i18next.t("generatePreInvoiceLastMonthQuestion")}`,
            title: i18next.t("preInvoiceLastMonthTitle"),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            showDenyButton: true,
            cancelButtonColor: "#d33",
            denyButtonColor: "#049942",
            confirmButtonText: i18next.t("confirm"),
            denyButtonText: "No",
            cancelButtonText: i18next.t("cancel"),
          }).then(({ isConfirmed, isDenied }) => {
            if (isConfirmed) {
              generatePreInvoiceLastMonth()
            }
            if (isDenied) {
              validateHour()
            }
          });
        }
        if (data.statusCode === 400) {
          validateHour();
        }
      }
      )
  };

  return (
    <AddDetailComponent
      value={addItemInvoiceDialog}
      onDisabled={onDisabledAddItemInvoiceDialog}
      thisLoading={thisLoading}
      notLoading={notLoading}
      onActive={onActiveAddItemInvoiceDialog}
      urlAddItem={urlAddItem}
      urlPreInvoicePending={urlPreInvoicePending}
      urlConfirmPreInvoice={urlConfirmPreInvoice}
      t={i18next.t}
    />
  );
};
