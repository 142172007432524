import {Link} from "react-router-dom";
import {Grid} from "@mui/material";
import {InsertDriveFile} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import MDBox from "../../components/MDBox";
import MenuCard from "../../examples/Cards/MenuCards";
import {MainLayout} from "../layout/MainLayout";

export const VacancyView = () => {
    const {t} = useTranslation();
    return(
        <MainLayout loading={false}>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4} xl={4}>
                        <MDBox mb={1.5}>
                            <Link to="/gestion-de-vacantes/vacantes">
                                <MenuCard
                                    icon={InsertDriveFile}
                                    title={t("vacancies")}
                                />
                            </Link>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={4}>
                        <MDBox mb={1.5}>
                            <Link to="/gestion-de-vacantes/candidatos">
                                <MenuCard
                                    icon={InsertDriveFile}
                                    title={t("submittedCandidates")}
                                />
                            </Link>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </MainLayout>
    )
}
