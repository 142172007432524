import axios from "axios";
import { ConfigHeaderBlobResponse } from "../utils/configHeader";

export const CustomAxios = axios.create({
    baseURL: "/v1/"
})

export const downloadFile = (url, filename) => {
    return CustomAxios.get(url, ConfigHeaderBlobResponse())
        .then(({ data, headers }) => {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = filename;
            link.href = window.URL.createObjectURL(
                new Blob([data], {
                    type: headers["content-type"],
                })
            );
            link.click();
        })
    }

export const showFile = (url) => {
    return CustomAxios.get(url, ConfigHeaderBlobResponse())
        .then(({ data, headers }) => {
            const blob = new Blob([data], {
                type: "application/pdf",
            });

            const objectUrl = window.URL.createObjectURL(blob);
            const windowFile = window.open(objectUrl, "_blank");

            if (!windowFile) {
                throw new Error("Failed to open popup. Please allow pop-ups for this site.");
            }

            setTimeout(() => {
                window.URL.revokeObjectURL(objectUrl);
            }, 5000);
        })
}
