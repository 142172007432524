import React, { useState, useEffect } from 'react'

import { IconButton, Menu, MenuItem } from '@mui/material';
import { Translate } from '@mui/icons-material';
import { useTranslation } from "react-i18next";

export default function TranslateComponent() {
    const { t, i18n: { changeLanguage, language } } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onSelectedES = () => {
        setAnchorEl(null);
        changeLanguage('es');
        localStorage.setItem("language", 'es');
    };
    const onSelectedEN = () => {
        setAnchorEl(null);
        changeLanguage('en');
        localStorage.setItem("language", 'en');
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        let languageSave = localStorage.getItem("language")
        if (languageSave) {
            changeLanguage(languageSave);
        }
    }, [])
    
    return (
        <>
            <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <Translate />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant='select'
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem selected={language === "es"} onClick={onSelectedES}>{t("es")}</MenuItem>
                <MenuItem selected={language === "en"} onClick={onSelectedEN}>{t("en")}</MenuItem>
            </Menu>
        </>
    )
}
